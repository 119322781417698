import { useForm } from 'react-hook-form'

import { useGoogleReCaptcha } from '~/providers/RecaptchaProvider/use-google-recaptcha'

import useAlerts from '~/hooks/useAlerts'

export default function useFormWithMutation(
  mutation?: any,
  formOptions?: any,
  action?: string,
  onInvalid?: (errors) => void,
) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const triggerAlert = useAlerts()

  const form = useForm({
    ...formOptions,
  })

  const submitHandler = form.handleSubmit(async (data) => {
    if (action) {
      try {
        const token = await executeRecaptcha(action)

        if (token) mutation(data)
        else triggerAlert('GOOGLE_RECAPTCHA_ERROR')
      } catch (err) {
        console.error('Recaptcha Error', err)
        triggerAlert('GOOGLE_RECAPTCHA_ERROR')
      }
    } else {
      mutation?.(data)
    }
  }, onInvalid)

  return { form, onSubmit: submitHandler }
}
