import classnames from 'classnames/bind'
import { CSSProperties, MouseEventHandler, ReactNode } from 'react'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface RatioProps {
  className?: string
  preset?: string
  styleContainer?: CSSProperties
  style?: CSSProperties
  onClick?: MouseEventHandler<any> | undefined
  ratio?: number
  children: (className: string) => ReactNode | ReactNode
}
/**
 * Ratio component to keep a proportion
 * @param props
 * @returns Component
 */
function Ratio({
  ratio,
  className,
  preset,
  styleContainer = {},
  children,
  ...rest
}: RatioProps) {
  const presetStyle = useStyle({
    ratio: preset,
  })
  const containerStyle = cx(css.ratioContainer, presetStyle)

  return (
    <div {...rest} className={cx(css.Ratio, className)}>
      <div
        className={containerStyle}
        style={
          ratio
            ? {
                paddingBottom: `${(1 / ratio) * 100}%`,
                ...styleContainer,
              }
            : { ...styleContainer }
        }>
        {children(css.children)}
      </div>
    </div>
  )
}

Ratio.defaultProps = {}

export type { Ratios } from './maths'
export default Ratio
