export const STORYBLOK_TYPES = {
  ERROR_PAGE: 'error_page',
  HOMEPAGE: '',
  CONTACT: 'contact_page',
  FAQ: 'faq_page',
  SEARCH: 'search',
  LIBRARY_UI: 'library_ui',
  UNIVERSAL: 'universal_page',
  PRODUCT: 'products',
  CATEGORY_PAGE: 'categories',
  INGREDIENT: 'ingredient_page',
  PRIVACY_POLICY: 'privacy_policy_page',
  COMING_SOON: 'universal_page/coming-soon',
  REGISTER: 'register',
  LOGIN: 'login',
  ACCOUNT: 'account',
  RESET_PASSWORD: 'reset-password',
  ENABLE_ACCOUNT: 'enable-account',
  FORGOT_PASSWORD: 'forgot-password',
  REVIEW: 'review',
} as const

export const STORYBLOK_REPEATABLE_TYPES = [
  STORYBLOK_TYPES.UNIVERSAL,
  STORYBLOK_TYPES.PRODUCT,
  STORYBLOK_TYPES.CATEGORY_PAGE,
]

export type TStoryblokSliceKey =
  (typeof STORYBLOK_SLICES)[keyof typeof STORYBLOK_SLICES]

export const STORYBLOK_SLICES = {
  PRODUCT_TITLE_SLIDER: 'product_title_slider',
  PUSH_GRID: 'push_grid',
  MEDIA_GRID: 'media_grid',
  PUSH_PRODUCTS: 'push_products',
  LIST_WORKSHOPS: 'list_workshops',
  BANNER: 'banner',
  HERO_MAIN: 'hero_main',
  HERO_BANNER: 'hero_banner',
  HORIZONTAL_MEDIA: 'horizontal_media',
  PUSH_SLIDER: 'push_slider',
  BLOG_GRID: 'blog_grid',
  PRODUCT_HEADER: 'product_header',
  EDITORIAL_TEXT: 'editorial_text',
  EDITORIAL_TWO_COLUMNS_IMAGE_TEXT: 'editorial_two_columns_image_text',
  EDITORIAL_IMAGES_COLUMNS: 'editorial_images_columns',
  EDITORIAL_SLIDER_IMAGES_WITH_CAPTIONS:
    'editorial_slider_images_with_captions',
  HERO_EDITORIAL: 'hero_editorial',
  EDITORIAL_COLORED_BLOCK: 'editorial_colored_block',
  EDITORIAL_VIDEO: 'editorial_video',
  EDITORIAL_ACCORDIONS: 'editorial_accordions',
  EDITORIAL_PUSH_CONTACT: 'editorial_push_contact',
  EDITORIAL_TWO_COLUMNS_CONTACT: 'editorial_two_columns_contact',
} as const

export const STORYBLOK_COMPONENTS = {
  PUSH_GRID_ITEM: 'push_grid_item',
  PUSH_GRID_CATEGORY_ITEM: 'push_grid_category_item',
  IMAGE_WITH_CAPTION: 'image_with_caption',
  LINK: 'link',
} as const
