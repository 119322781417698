import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LazyMotion } from 'framer-motion'
import { useRouter } from 'next/router'
import { ReactNode, useMemo, useState } from 'react'
import { I18n } from 'react-polyglot'
import processEnvPublic from 'unlikely-env/public'
import { productVariantMetafields } from '~/lib/shopify/constants/metafields'
import { getStorePublicCredentials } from '~/lib/shopify/public-client'

import { AlertProvider } from '@unlikelystudio/react-abstract-components'
import {
  AnalyticsProvider,
  CartProvider,
  CustomerProvider,
  ShopifyAnalytics,
  StoreProvider,
} from '@unlikelystudio/react-ecommerce-hooks'
import {
  useFixScrollbarOverflow,
  useStableVh,
  useVh,
} from '@unlikelystudio/react-hooks'

import AlertCenter from '~/components/Abstracts/AlertCenter'
import CartAutomaticOpening from '~/components/Cart/CartAutomaticOpening'
import { NavBannerContextProvider } from '~/components/Navigation/Navigation/NavBanner'
import { ToastProvider } from '~/components/Toast'

import BannerProvider from '~/providers/BannerProvider'
import BreadcrumbProvider from '~/providers/BreadcrumbProvider'
import ClickOutsideProvider from '~/providers/ClickOutsideProvider'
import GlobalDataProvider from '~/providers/GlobalDataProvider'
import NavigationProvider from '~/providers/NavigationProvider'
import PanelProvider from '~/providers/PanelProvider'
import PopInProvider from '~/providers/PopInProvider'
import { GoogleReCaptchaProvider } from '~/providers/RecaptchaProvider/google-recaptcha-provider'
import StyleProvider from '~/providers/StyleProvider'
import ThemeProvider from '~/providers/ThemeProvider'
import TrackerProvider from '~/providers/TrackerProvider'

import useIsIOS from '~/hooks/useIsIOS'

import { getLang } from '~/utils/locales'

import { DefaultPageData } from '~/data/page-data/serializer'

import formsPreset from '~/styles/forms-presets/styles.module.scss'
import gridPresets from '~/styles/grid-presets/styles.module.scss'
import ratioPresets from '~/styles/ratio-presets/styles.module.scss'
import textPresets from '~/styles/text-presets/styles.module.scss'
import stylingPresets from '~/styles/text-presets/styling.module.scss'
import backgroundColors from '~/styles/theme-classes/background-color.module.scss'
import borderColors from '~/styles/theme-classes/border-color.module.scss'
import colors from '~/styles/theme-classes/colors.module.scss'
import themePresets from '~/styles/theme-presets/styles.module.scss'

import CartPanelProvider from '../CartProvider'

const motionAsyncFeatures = () =>
  import('~/utils/motion-features').then((res) => res.default)

export interface AppProviderProps {
  children: ReactNode | ReactNode[]
  pageProps: DefaultPageData
}

export const stylePresets = {
  textPreset: textPresets,
  textStyling: stylingPresets,
  color: colors,
  formsPreset: formsPreset,
  grid: gridPresets,
  ratio: ratioPresets,
  backgroundColor: backgroundColors,
  borderColor: borderColors,
} as const

interface ReCaptchaProps {
  nonce?: string
  defer?: boolean
  async?: boolean
  appendTo?: 'head' | 'body'
  id?: string
}

function AppProvider({ children, pageProps }: AppProviderProps) {
  const { locale } = useRouter()
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    }),
  )

  const memoizedGlobalProviderData = useMemo(
    () => ({
      global: pageProps?.global,
      metas: pageProps?.metas,
      theme: pageProps?.theme,
    }),
    [pageProps],
  )

  const navigationValues = useMemo(
    () => ({
      productHeaderVisible: false,
    }),
    [],
  )

  const recaptachaProps: ReCaptchaProps = useMemo(
    () => ({
      async: true,
      defer: true,
      appendTo: 'body',
    }),
    [],
  )

  useIsIOS()
  useStableVh()
  useVh()
  useFixScrollbarOverflow()

  // const clientConsent = useClientConsent()

  const shopifyCredential = getStorePublicCredentials()
  const languageCode = getLang(locale)?.toUpperCase()
  return (
    <LazyMotion strict features={motionAsyncFeatures}>
      <GoogleReCaptchaProvider
        useEnterprise
        reCaptchaKey={processEnvPublic('NEXT_PUBLIC_RECAPTCHA_API_KEY')}
        {...recaptachaProps}>
        <NavBannerContextProvider>
          <QueryClientProvider client={queryClient}>
            <StoreProvider
              storeUrl={shopifyCredential.storeUrl}
              storefrontAccessToken={shopifyCredential.storefrontAccessToken}
              currency="EUR"
              locale={locale}
              country={'FR'}
              language={languageCode}>
              <CustomerProvider>
                <CartProvider
                  cartInput={{
                    includeQuantityAvailable: true,
                    metafieldVariantsKeys: [
                      productVariantMetafields.variantInventoryIsTracked,
                    ],
                  }}>
                  <AnalyticsProvider>
                    <ShopifyAnalytics hasUserConsent={true} />
                    <CartPanelProvider>
                      <TrackerProvider>
                        <I18n
                          locale={locale}
                          messages={pageProps?.global?.dictionary ?? {}}
                          allowMissing={true}>
                          <GlobalDataProvider
                            value={memoizedGlobalProviderData}>
                            <StyleProvider value={stylePresets}>
                              <ThemeProvider value={themePresets}>
                                <AlertProvider>
                                  <AlertCenter />
                                  <ToastProvider>
                                    <PanelProvider
                                      data={pageProps?.global?.panels}>
                                      <PopInProvider>
                                        <BreadcrumbProvider
                                          links={pageProps?.breadcrumb}>
                                          <NavigationProvider
                                            value={navigationValues}>
                                            <BannerProvider>
                                              <ClickOutsideProvider>
                                                {children}
                                                <CartAutomaticOpening />
                                              </ClickOutsideProvider>
                                            </BannerProvider>
                                          </NavigationProvider>
                                        </BreadcrumbProvider>
                                      </PopInProvider>
                                    </PanelProvider>
                                  </ToastProvider>
                                </AlertProvider>
                              </ThemeProvider>
                            </StyleProvider>
                          </GlobalDataProvider>
                        </I18n>
                      </TrackerProvider>
                    </CartPanelProvider>
                  </AnalyticsProvider>
                </CartProvider>
              </CustomerProvider>
            </StoreProvider>
          </QueryClientProvider>
        </NavBannerContextProvider>
      </GoogleReCaptchaProvider>
    </LazyMotion>
  )
}

export default AppProvider
