import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import AbstractBreadcrumb from '~/components/Abstracts/Breadcrumb'

import { useBreadCrumb } from '~/providers/BreadcrumbProvider'
import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BreadcrumbProps {
  className?: string
}

function Breadcrumb({ className, ...rest }: BreadcrumbProps) {
  const textStyle = useStyle({
    color: GlobalThemeColors.Gray1ToBlack,
    textPreset: GlobalTextPreset.Label10Secondary,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const { links } = useBreadCrumb()

  return (
    <AbstractBreadcrumb
      links={links}
      className={cx(css.Breadcrumb, className)}
      itemClassName={cx(textStyle, css.item)}
      separatorClassName={css.separator}
      {...rest}
    />
  )
}

Breadcrumb.defaultProps = {}

export default Breadcrumb
