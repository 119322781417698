import { useTranslate } from 'react-polyglot'

import { InputProps } from '~/components/Abstracts/Form/Input'

import {
  ADDRESS_REGEX,
  EMAIL_REGEX,
  ONLY_NUMBERS_REGEX,
  TEL_REGEX,
} from '~/utils/regex'

import { FORM, NOTE } from '~/data/dictionary'

const dateOffset = 1000 * 60 * 60 * 24 * 365 * 10
const minDate = '1900-01-01'
const maxDate = new Date(Date.now() - dateOffset)
  ?.toISOString()
  ?.split('T')?.[0]

const inputPresets = {
  addressName: {
    type: 'text',
    required: true,
    name: 'address',
    maxLength: 35,
    placeholder: FORM.ADDRESS_NAME,
    validations: null,
  },
  city: {
    type: 'text',
    required: true,
    autoComplete: 'address-level2',
    name: 'city',
    placeholder: FORM.CITY,
    validations: [{ regex: ADDRESS_REGEX, key: 'letters' }],
  },
  email: {
    type: 'text',
    required: true,
    autoComplete: 'email',
    placeholder: FORM.EMAIL,
    name: 'email',
    validations: [{ regex: EMAIL_REGEX, key: 'email' }],
  },
  firstName: {
    type: 'text',
    required: true,
    placeholder: FORM.FIRST_NAME,
    name: 'firstName',
    autoComplete: 'given-name',
    validations: null,
  },
  lastName: {
    type: 'text',
    required: true,
    placeholder: FORM.LAST_NAME,
    name: 'lastName',
    autoComplete: 'family-name',
    validations: null,
  },
  streetName: {
    type: 'text',
    required: true,
    name: 'address1',
    placeholder: FORM.STREET_NAME,
    validations: null,
  },
  infoAddress: {
    type: 'text',
    required: false,
    name: 'address2',
    placeholder: FORM.INFOS_ADDRESS,
    validations: null,
  },
  region: {
    type: 'text',
    required: false,
    name: 'region',
    placeholder: FORM.REGION,
    validations: null,
  },
  country: {
    type: 'text',
    required: false,
    name: 'country',
    placeholder: FORM.COUNTRY,
    validations: null,
  },
  state: {
    type: 'text',
    required: false,
    name: 'state',
    placeholder: FORM.STATE,
    validations: null,
  },
  zipCode: {
    type: 'text',
    required: true,
    placeholder: FORM.ZIP_CODE,
    name: 'zip',
    autoComplete: 'postal-code',
    validations: null,
  },
  search: {
    type: 'text',
    required: false,
    placeholder: FORM.SEARCH,
    name: 'search',
    validations: null,
  },
  tel: {
    type: 'tel',
    required: false,
    placeholder: FORM.TEL,
    name: 'tel',
    validations: [{ regex: TEL_REGEX, key: 'tel' }],
  },
  birthDate: {
    type: 'date',
    required: true,
    placeholder: FORM.BIRTH_DATE,
    name: 'birthDate',
    validations: null,
    min: minDate,
    max: maxDate,
  },
  password: {
    type: 'password',
    placeholder: FORM.PASSWORD,
    name: 'password',
    minLength: 8,
    validations: null,
  },
  newPassword: {
    type: 'password',
    placeholder: FORM.NEW_PASSWORD,
    name: 'new-password',
    minLength: 8,
    validations: null,
  },
  repeatPassword: {
    type: 'password',
    placeholder: FORM.REPEAT_PASSWORD,
    name: 'repeat-password',
    minLength: 8,
    validations: null,
  },
  repeatNewPassword: {
    type: 'password',
    placeholder: FORM.REPEAT_NEW_PASSWORD,
    name: 'repeat-new-password',
    minLength: 8,
    validations: null,
  },
  reviewTitle: {
    type: 'text',
    required: false,
    name: 'title',
    placeholder: FORM.REVIEW_TITLE,
    validations: null,
  },
  reviewBody: {
    type: 'textarea',
    required: true,
    name: 'body',
    placeholder: FORM.REVIEW_BODY,
    validations: null,
  },
  reviewRating: {
    type: 'text',
    required: true,
    name: 'rating',
    placeholder: FORM.REVIEW_RATING,
    validations: [{ regex: ONLY_NUMBERS_REGEX, key: 'rating' }],
  },
  fullName: {
    type: 'text',
    required: true,
    autoComplete: '',
    name: 'name',
    placeholder: FORM.FULL_NAME,
    validations: null,
  },
  starRating: {
    type: 'radio',
    required: true,
    name: 'rating',
    placeholder: '',
    validations: null,
  },
  signature: {
    type: 'text',
    name: 'signature',
    validations: null,
    placeholder: NOTE.SIGNATURE_PLACEHOLDER,
  },
  note: {
    required: true,
    name: 'note',
    validations: null,
    placeholder: NOTE.MESSAGE_PLACEHOLDER,
  },
} as const

export type TInputPresets = keyof typeof inputPresets

export default function useInputPreset<T extends InputProps>(
  key: TInputPresets,
  args: T,
): Partial<T> {
  const t = useTranslate()
  const currentPreset = inputPresets?.[key]

  const validate =
    currentPreset?.validations?.length > 0 &&
    currentPreset?.validations?.reduce((acc: any, { key, regex }) => {
      acc[key] = (val) => {
        return regex.test(val)
      }
      return acc
    }, {})

  if (!inputPresets[key]) return args

  return {
    ...currentPreset,
    validate,
    placeholder: args?.placeholder || t(currentPreset?.placeholder),
    ...args,
  }
}
