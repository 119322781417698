import classnames from 'classnames/bind'
import { InputHTMLAttributes, ReactNode, useEffect } from 'react'
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFormContext,
} from 'react-hook-form'

import objectFilter from '~/utils/filter-object'

import css from './styles.module.scss'

const cx = classnames.bind(css)
export interface InputProps
  extends Omit<InputHTMLAttributes<any>, 'children' | 'onError'> {
  className?: string
  textarea?: boolean
  rows?: number
  forwardRef?: any
  withError?: boolean
  errorClassname?: string
  addRequiredIndicatorOnLabel?: boolean
  validate?: Record<string, (val?: unknown) => boolean>
  isRegistered?: boolean
  children?:
    | ReactNode
    | JSX.Element
    | (({ withError }: { withError: boolean }) => JSX.Element)
    | null
  onError?: (node: FieldError | Merge<FieldError, FieldErrorsImpl<any>>) => void
}

function Input({
  className,
  forwardRef,
  type,
  textarea,
  rows,
  withError,
  errorClassname,
  name,
  required,
  placeholder,
  addRequiredIndicatorOnLabel,
  validate,
  min,
  max,
  minLength,
  maxLength,
  defaultValue,
  defaultChecked,
  isRegistered = true,
  onError,
  children,
  ...inputProps
}: InputProps) {
  const { register, formState } = useFormContext() || {}

  const error = formState?.errors?.[name] ?? null
  const placeHolder = placeholder
    ? placeholder + (addRequiredIndicatorOnLabel && required ? ' *' : '')
    : null

  const validations = {
    required,
    min,
    max,
    minLength,
    maxLength,
    defaultValue,
    defaultChecked,
    validate,
  }

  const filteredValidations = objectFilter(
    validations,
    ([, val]) => val !== undefined,
  )

  const cssError =
    (withError || error?.type) && errorClassname ? errorClassname : null

  useEffect(() => {
    onError?.(error)
  }, [cssError])
  const textareaComponent = (
    <>
      <textarea
        className={cx(css.textarea, className, cssError)}
        rows={rows}
        name={name}
        maxLength={maxLength}
        defaultValue={defaultValue}
        data-value={inputProps?.value ?? null}
        placeholder=" "
        ref={forwardRef}
        {...inputProps}
        {...(error?.type ? { 'aria-describedby': 'req' } : {})}
        {...(name && register?.(name, filteredValidations))}
      />
      {placeHolder && (
        <label htmlFor={name} className={css.floatingLabel}>
          {placeHolder}
        </label>
      )}
    </>
  )

  const inputComponent = (
    <>
      <input
        type={type}
        name={name}
        maxLength={maxLength}
        defaultChecked={defaultChecked}
        defaultValue={defaultValue}
        ref={forwardRef}
        placeholder=" "
        className={cx(css.input, cssError, className)}
        {...inputProps}
        {...(error?.type ? { 'aria-describedby': 'req' } : {})}
        {...(name && register?.(name, filteredValidations))}
      />
      {placeHolder && (
        <label htmlFor={name} className={css.floatingLabel}>
          {placeHolder}
        </label>
      )}
    </>
  )

  return textarea ? textareaComponent : inputComponent
}

Input.defaultProps = {
  addRequiredIndicatorOnLabel: true,
}

export default Input
