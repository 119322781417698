import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import React, { useCallback, useEffect } from 'react'

import { usePopIn } from '~/providers/PopInProvider'

import useBodyScrollLock from '~/hooks/useBodyScrollLock'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PopInManagerProps {
  className?: string
}

function PopInManager({ className }: PopInManagerProps) {
  const { popIns, removeCurrent } = usePopIn()

  const current = popIns?.length > 0 ? popIns[popIns.length - 1] : null
  const asBackground = current?.options?.withBackground

  useBodyScrollLock(asBackground, null, 'popins')

  const onEscDown = useCallback(
    (e) => {
      if (e.keyCode == 27 && current?.actions?.clickOutside) removeCurrent()
    },
    [removeCurrent, current],
  )

  useEffect(() => {
    window.addEventListener('keydown', onEscDown)

    return () => window.removeEventListener('keydown', onEscDown)
  }, [onEscDown])

  return (
    <AnimatePresence>
      {popIns?.length > 0 && (
        <div
          className={cx(css.PopInManager, {
            show: popIns?.length > 0,
          })}>
          {asBackground && (
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={css.background}
              onClick={() => {
                if (current?.actions?.clickOutside) removeCurrent()
              }}
            />
          )}
          <m.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            className={cx(
              className,
              css.PopInManagerWrapper,
              css?.[`vertical-${current?.options?.verticalAlignement}`],
              css?.[`horizontal-${current?.options?.horizontalAlignement}`],
            )}>
            {current?.content}
          </m.div>
        </div>
      )}
    </AnimatePresence>
  )
}

PopInManager.defaultProps = {}

export default PopInManager
