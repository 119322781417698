import classnames from 'classnames/bind'
import { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useWindowSize } from 'react-use'
import { GlobalThemeColors } from '~/@types/colors'
import { FirstLevelNavItem } from '~/@types/navigation'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { useIsHover, useMeasureObserver } from '@unlikelystudio/react-hooks'

import PushNotification from '~/components/Abstracts/PushNotification'
import BlurredCta from '~/components/UI/BlurredCta'
import NavItemCta from '~/components/UI/NavItemCta'

import { useNavPanelLinks } from '~/providers/NavPanelLinksProvider'
import { useStyle } from '~/providers/StyleProvider'

import { PANEL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export default function NavigationPanel({
  onMouseEnter,
  onMouseLeave,
  secondLevelItems,
  enableSeeAll,
  link,
  push,
}: FirstLevelNavItem) {
  const t = useTranslate()
  const { width: windowWidth } = useWindowSize()
  const panelRef = useRef()
  const [, mouseEvents] = useIsHover({
    onMouseEnter,
    onMouseLeave,
  })
  const { hoverLink, topLinksBounds } = useNavPanelLinks()
  const [panelX, setPanelX] = useState(0)
  const panelBounds = useMeasureObserver(panelRef, 'getBoundingClientRect')

  const columnTitleStyle = useStyle({
    color: GlobalThemeColors.Black,
    textPreset: GlobalTextPreset.Cta12Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })

  useEffect(() => {
    if (!hoverLink) return
    const { x, width } = hoverLink

    if (x) {
      if (panelBounds?.left > x) {
        setPanelX(x - panelBounds?.left)
      } else if (panelBounds?.left + panelBounds?.width < x + width) {
        setPanelX(x + width - panelBounds?.left - panelBounds?.width)
      } else {
        setPanelX(0)
      }
    }
  }, [panelBounds?.left, windowWidth])

  return (
    <div
      className={cx(css.NavigationPanel, {
        hasPush: push,
        enableSeeAll: enableSeeAll && link,
      })}
      {...mouseEvents}>
      <div
        className={cx(css.panelWrapper)}
        style={{
          width: topLinksBounds?.width,
        }}>
        <div ref={panelRef}>
          <div
            className={cx(css.panelContent)}
            style={{
              transform: `translate3d(calc(${panelX}px), 0, 0)`,
            }}>
            <div className={cx(css.background)} />
            {secondLevelItems?.length > 0 &&
              secondLevelItems?.some((column) => column?.links?.length > 0) && (
                <div className={cx(css.panelLinks)}>
                  <div className={cx(css.columns)}>
                    {secondLevelItems?.map((column, columnIndex) => {
                      return (
                        <div
                          className={css.column}
                          key={`panel_column_${columnIndex}`}>
                          {column?.title && (
                            <h5
                              className={cx(css.columnTitle, columnTitleStyle)}>
                              {column?.title}
                            </h5>
                          )}
                          {column?.links?.[0]?.map((link, index) => {
                            return (
                              <NavItemCta
                                key={`second_level_link_${columnIndex}_${index}`}
                                className={css.columnLink}
                                uppercase={false}
                                isActive
                                withBackground={false}
                                textPreset={GlobalTextPreset.Cta14PrimaryReg}
                                {...link}
                              />
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                  {enableSeeAll && link && (
                    <BlurredCta
                      className={cx(css.seeAll)}
                      theme={GlobalThemeColors.Black}
                      {...link}>
                      {t(PANEL.SEE_ALL)}
                    </BlurredCta>
                  )}
                </div>
              )}
            {push && secondLevelItems?.length <= 2 && (
              <div className={cx(css.columnPush, { imageOnly: !push?.link })}>
                <PushNotification
                  className={cx(css.push)}
                  isMenuDesktop
                  {...push}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
