import classnames from 'classnames/bind'
import { TThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import AbstractInput, {
  InputProps as AbstratInputprops,
} from '~/components/Abstracts/Form/Input'
import {
  TInputPresets,
  useInputPreset,
} from '~/components/Abstracts/Form/Input/hooks'
import ErrorList from '~/components/Form/ErrorList'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface InputProps extends AbstratInputprops {
  className?: string
  inputClassName?: string
  preset?: TInputPresets
  inputStyle?: string
  theme?: TThemeColors
}

function Input({
  className,
  inputClassName,
  errorClassname,
  preset,
  inputStyle,
  theme,
  ...rest
}: InputProps) {
  const defaultInputStyle = useStyle({
    textPreset: GlobalTextPreset.Input16Primary,
  })

  const props = useInputPreset(preset, rest)

  return (
    <div
      className={cx(
        css.Input,
        className,
        css?.[preset],
        css?.[`theme-${theme}`],
        { textArea: rest.textarea },
      )}>
      <AbstractInput
        className={cx(
          css.input,
          inputStyle ? inputStyle : defaultInputStyle,
          inputClassName,
        )}
        errorClassname={cx(css.error, errorClassname)}
        {...props}
      />
      <ErrorList className={cx(css.errorList)} name={props.name} />
    </div>
  )
}

Input.defaultProps = {}

export default Input
