import { DOMAttributes, forwardRef, ReactNode } from 'react'
import { UrlObject } from 'url'

import { useIsHover } from '@unlikelystudio/react-hooks'

import Link, { LinkProps } from '~/components/Abstracts/Link'
import Spinner from '~/components/Abstracts/Spinner'

import useDeviceType, { DeviceType } from '~/hooks/useDeviceType'

export interface BaseProps extends Omit<DOMAttributes<any>, 'children'> {
  className?: string
  href?: string | UrlObject
  target?: string
  children?:
    | ReactNode
    | JSX.Element
    | (({ isHover }: { isHover: boolean }) => JSX.Element)
    | null
  theme?: string
  isLoading?: boolean
  withBackground?: boolean
  withBorder?: boolean
  withHover?: boolean
  innerRef?: any
  disabled?: boolean
  title?: string
  isDiv?: boolean
  negativeColor?: string
  type?: 'submit' | 'reset' | 'button' | undefined
  cx?: (...args: any[]) => string
  css?: Record<string, string>
}

export type CtaProps = BaseProps | (LinkProps & BaseProps)

function Cta({
  className,
  children,
  href,
  withBackground,
  theme,
  onMouseEnter,
  onMouseLeave,
  withBorder,
  withHover,
  innerRef,
  isLoading,
  isDiv,
  negativeColor,
  disabled,
  cx,
  css,
  ...rest
}: CtaProps) {
  const [isHover, mouseEvents] = useIsHover({
    onMouseEnter,
    onMouseLeave,
  })

  const device = useDeviceType()

  const classNames = cx(className, {
    withBackground,
    withBorder,
    hovered: device === DeviceType.mobile ? false : isHover,
    loading: isLoading,
    [`${theme}Theme`]: theme,
    disabled,
  })

  const CtaChildren = (
    <>
      {isLoading && (
        <div className={cx(css.spinner)}>
          <Spinner color={negativeColor} />
        </div>
      )}
      <div className={cx(css.childrenContent, { isLoading })}>
        {typeof children === 'function' ? children({ isHover }) : children}
      </div>
    </>
  )

  return href ? (
    <Link
      href={href}
      ref={innerRef}
      className={classNames}
      {...mouseEvents}
      {...rest}>
      {CtaChildren}
    </Link>
  ) : isDiv ? (
    <div className={classNames} ref={innerRef} {...mouseEvents} {...rest}>
      {CtaChildren}
    </div>
  ) : (
    <button
      className={classNames}
      ref={innerRef}
      {...mouseEvents}
      {...rest}
      disabled={disabled}>
      {CtaChildren}
    </button>
  )
}

Cta.defaultProps = {
  withBackground: true,
  withBorder: false,
}

function CtaForwarded(props, ref) {
  return <Cta innerRef={ref} {...props} />
}

export default forwardRef<HTMLAnchorElement | HTMLButtonElement, CtaProps>(
  CtaForwarded,
)
