import { forwardRef } from 'react'
import { Richtext as RichTextBlock } from 'storyblok-js-client'
import { MARK_LINK, render } from 'storyblok-rich-text-react-renderer'
import { GlobalThemeColors } from '~/@types/colors'
import { STORYBLOK_COMPONENTS } from '~/lib/storyblok-types'

import Link from '~/components/Abstracts/Link'
import BlurredCta from '~/components/UI/BlurredCta'
import ImageWithCaption, {
  ImageWithCaptionProps,
} from '~/components/UI/ImageWithCaption'

import useLocale from '~/hooks/useLocale'

import { isRTFilled } from '~/utils/check-empty-string'

import serializeCta from '~/data/serialize-cta'
import serializeImage from '~/data/serialize-image'
import serializeLink from '~/data/serialize-link'

export interface RichTextProps {
  className?: string
  children?: undefined
  innerRef?: any
  style?: any
  render?: RichTextBlock
}
/**
 * Richtext component
 * @param props
 * @returns Component
 */
function RichText(
  { className, style, render: renderText }: RichTextProps,
  ref: any,
) {
  const locale = useLocale()

  return isRTFilled(renderText) ? (
    <div ref={ref} className={className} style={style}>
      {render(renderText, {
        markResolvers: {
          [MARK_LINK]: (children, props) => {
            const linkProps = serializeLink(
              { id: 'link-wysiwyg', ...props },
              locale,
            )
            return (
              <Link className={'inlineLink'} {...linkProps}>
                {children}
              </Link>
            )
          },
        },
        blokResolvers: {
          [STORYBLOK_COMPONENTS.LINK]: (props) => {
            const ctaProps = serializeCta(locale, props)

            return (
              <div className="ctaWrapper">
                <BlurredCta theme={GlobalThemeColors.Black} {...ctaProps} />
              </div>
            )
          },
          [STORYBLOK_COMPONENTS.IMAGE_WITH_CAPTION]: (
            props: ImageWithCaptionProps,
          ) => {
            const image = {
              caption: props?.caption ?? null,
              image: serializeImage(props?.image) ?? null,
              link: props?.link ? serializeLink(props?.link, locale) : null,
            }

            return (
              <div className="captionImageWrapper">
                <ImageWithCaption {...image} layout="responsive" />
              </div>
            )
          },
        },
      })}
    </div>
  ) : null
}

export type { Richtext as RichTextBlock } from 'storyblok-js-client'
export default forwardRef(RichText)
