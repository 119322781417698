import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import { registerToNewsletter } from '~/lib/handle-api'

import { useTracker } from '~/providers/TrackerProvider'

import useCurrency from '~/hooks/useCurrency'
import useLang from '~/hooks/useLang'
import useOnMutationError from '~/hooks/useOnMutationError'

export default function useShopifyNewsletterMutation(
  mutationOptions?: UseMutationOptions<any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const tracker = useTracker()
  const currency = useCurrency()
  const onMutationError = useOnMutationError(setError)
  const language = useLang()

  return useMutation<any, any, any>(
    async ({ email }) => {
      return await registerToNewsletter({
        store: currency,
        payload: { email, lang: language },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        //! standardize error response
        const error = data?.errors?.[0]
        const errorPayload = { payload: [data?.errors?.[0]] }

        if (error) {
          // tracker.emit('newslettererror')
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }

        tracker.emit('newslettersuccess')
        mutationOptions.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
        // tracker.emit('newslettererror')
      },
    },
  )
}
