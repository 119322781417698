import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import { lockScroll, unlockScroll } from '~/utils/universal-scroll-lock'

interface DBItem {
  node?: HTMLElement | HTMLElement[]
  shouldLock?: boolean
  key?: string
}

let DB: DBItem[] = []

function useBodyScrollLock(
  shouldLock: boolean,
  node?: HTMLElement | HTMLElement[],
  key?: string,
) {
  useIsomorphicLayoutEffect(() => {
    if (shouldLock) {
      // Find existing item
      const findedIndex = DB.findIndex((item) => item.node === node)

      // Check if there is item disable to unlock
      DB.forEach((item) => {
        if (item.shouldLock) {
          unlockScroll()
        }
      })

      // If no existing push it to DB
      if (findedIndex === -1) {
        DB.push({
          node,
          shouldLock,
          key,
        })
      } else {
        DB[findedIndex].shouldLock = true
      }

      lockScroll()
    } else {
      // If disable lock && node
      const findedIndex = DB.findIndex((item) => item.node === node)
      // If there is an item
      if (findedIndex !== -1) {
        // Set shouldLock to false to the next adding round
        DB[findedIndex].shouldLock = false

        // Check if there is at least one locking item
        const hasAtLeastOneItemLocking = DB?.findIndex(
          (item) => item.shouldLock === true,
        )
        // If there no lock item into db unlock the current one which is the last one to unlock
        if (hasAtLeastOneItemLocking === -1) {
          unlockScroll()
        }
      }
    }
  }, [shouldLock, node])

  useIsomorphicLayoutEffect(() => {
    return () => {
      // Unmount
      // Find current node into db
      const findedIndex = DB.findIndex((item) => item.node === node)

      // Disable lock item if we found a reference
      if (findedIndex !== -1) {
        DB[findedIndex].shouldLock = false
      }

      // Check if there is at least one locking item
      const hasAtLeastOneItemLocking = DB?.findIndex(
        (item) => item.shouldLock === true,
      )
      // If there no lock item into db unlock the current one which is the last one to unlock
      if (hasAtLeastOneItemLocking === -1) unlockScroll()

      // Delete current hook reference from DB
      DB = DB.filter((item) => item.node !== node)
    }
  }, [node])
}

export default useBodyScrollLock
