export enum GlobalTextPreset {
  /** Ctas */
  Cta10Primary = 'cta-10-primary',
  Cta11Primary = 'cta-11-primary',
  Cta12Primary = 'cta-12-primary',
  Cta12PrimaryReg = 'cta-12-primary-reg',
  Cta14PrimaryReg = 'cta-14-primary-reg',
  Cta18Primary = 'cta-18-primary',
  Cta8Secondary = 'cta-8-secondary',

  /** Labels */
  Label10Primary = 'label-10-primary',
  Label12Primary = 'label-12-primary',
  Label16Primary = 'label-16-primary',
  Label18Primary = 'label-18-primary',
  Label10Secondary = 'label-10-secondary',
  Label11Secondary = 'label-11-secondary',
  Label12_10Secondary = 'label-12-10-secondary',
  Label12Secondary = 'label-12-secondary',
  Label18PrimaryReg = 'label-18-primary-reg',
  Label18Secondary = 'label-18-secondary',

  /** Inputs */
  Input16Primary = 'input-16-primary',

  /** Titles */
  Title10Primary = 'title-10-primary',
  Title12Primary = 'title-12-primary',
  Title14Primary = 'title-14-primary',
  Title12_18Primary = 'title-12-18-primary',
  Title16Primary = 'title-16-primary',
  Title18Primary = 'title-18-primary',
  Title26Primary = 'title-26-primary',
  Title22Primary = 'title-22-primary',
  Title18_24Primary = 'title-18-24-primary',
  Title26_36Primary = 'title-26-36-primary',
  Title24_28Primary = 'title-24-28-primary',
  Title28Primary = 'title-28-primary',
  TitleFluid24_44Primary = 'title-fluid-24-44-primary',
  TitleFluid68_96Primary = 'title-fluid-68-96-primary',

  /** Texts */
  Text10Primary = 'text-10-primary',
  Text10Secondary = 'text-10-secondary',
  Text12Secondary = 'text-12-secondary',
  Text16Secondary = 'text-16-secondary',
  Text18Secondary = 'text-18-secondary',

  /** WYSIWYGs */
  RichTextVariantProduct = 'rich-text-variant-product',
  RichTextEditorial = 'rich-text-editorial',
  RichTextBanner = 'rich-text-banner',
}
