import { GlobalThemeColors, ThemePageColors } from '~/@types/colors'

import { useGlobalData } from '~/providers/GlobalDataProvider'

export interface UseTextColorFromThemeReturnType {
  textColor?: GlobalThemeColors.Black | GlobalThemeColors.White
}

function useTextColorFromTheme(
  componentTheme?: ThemePageColors,
): UseTextColorFromThemeReturnType {
  const { theme } = useGlobalData()
  const globalTheme = theme?.page
  let textColor: ThemePageColors

  switch (componentTheme ?? globalTheme) {
    case GlobalThemeColors.Black:
      textColor = GlobalThemeColors.White
      break
    case GlobalThemeColors.White:
      textColor = GlobalThemeColors.Black
      break
      break
  }

  return { textColor }
}

export default useTextColorFromTheme
