import classnames from 'classnames/bind'
import { BreadcrumbJsonLd } from 'next-seo'
import React from 'react'

import Link, { LinkProps } from '~/components/Abstracts/Link'

import serializeJSONLD from './serialize-json-ld'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BreadcrumbProps {
  className?: string
  itemClassName?: string
  separatorClassName?: string
  links?: LinkProps[]
}

function Breadcrumb({
  className,
  separatorClassName,
  itemClassName,
  links,
}: BreadcrumbProps) {
  return (
    <div className={cx(className)}>
      <BreadcrumbJsonLd {...serializeJSONLD({ links })} />
      <div className={css.Breadcrumb}>
        {links?.map((link, index) => (
          <div className={cx(css.item, itemClassName)} key={index}>
            {index > 0 && <div className={separatorClassName}>/</div>}
            <Link
              className={cx(link?.href ? '' : css.noLink, css.link)}
              {...link}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

Breadcrumb.defaultProps = {}

export default Breadcrumb
