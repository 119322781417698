import { UseQueryOptions } from '@tanstack/react-query'
import { useRef } from 'react'
import { CheckoutProps } from '~/@types/cart'

import { UnlikelyCart } from '@unlikelystudio/commerce-connector'
import {
  useGetCart as useAbstractGetCart,
  useUpdateCartAttributes,
  useUpdateCartLines,
} from '@unlikelystudio/react-ecommerce-hooks'

import useAlerts from '~/hooks/useAlerts'
import useLocale from '~/hooks/useLocale'

import { getQuantityCorrectionFromUpdatedLineItems } from '~/utils/get-quantity-correction-from-updated-line-items'

import serializeCartProducts from '~/data/checkout-data/serialize-cart-products'
import serializeCart from '~/data/checkout-data/serialize-checkout'

export function useGetCart(mutationOptions?: UseQueryOptions<any, any, any>) {
  const locale = useLocale()
  const { mutate: updateItems } = useUpdateCartLines()
  const triggerAlerts = useAlerts()
  const isMutatingCart = useRef(false)

  const prevCart = useRef<UnlikelyCart>(null)

  const { mutate: updateCartCustomAttribute, isLoading: isUpdatingCart } =
    useUpdateCartAttributes({
      onSuccess: () => {
        isMutatingCart.current = false
      },
    })

  const {
    data: cartPayload,
    isLoading: isAbstractLoading,
    ...rest
  } = useAbstractGetCart({
    onSuccess: (cart) => {
      // Look for the line items that have been updated since last cart update
      const updatedLines = cart?.lines?.filter(
        (line) =>
          prevCart?.current?.lines?.findIndex(
            (prevLine) => prevLine?.id === line?.id,
          ) < 0 ||
          line.quantity !==
            prevCart?.current?.lines?.find(
              (prevLine) => prevLine?.id === line?.id,
            )?.quantity,
      )

      // if some lines were updated, we need to check for each product item if its corresponding lines quantity is correct
      if (updatedLines?.length > 1) {
        const products = serializeCartProducts(updatedLines ?? [], locale)
        products.forEach((product) => {
          const quantityCorrection = getQuantityCorrectionFromUpdatedLineItems(
            cart,
            product.items,
          )

          if (Number(quantityCorrection) && quantityCorrection >= 0) {
            const updatedCartLines = cart.lines.filter((line) =>
              product.items.find(
                (item) => line.merchandise.id === item.merchandise.id,
              ),
            )

            updateItems(
              updatedCartLines?.map((item) => ({
                id: item?.id,
                merchandiseId: item?.merchandise?.id,
                quantity: quantityCorrection,
                attributes: item?.attributes,
              })),
            )
            triggerAlerts('QUANTITY_CORRECTED')
          }
        })
      }
      prevCart.current = cart
      mutationOptions?.onSuccess && mutationOptions.onSuccess(cart)
    },
  })

  const checkout = serializeCart(cartPayload, locale)
  const products = serializeCartProducts(cartPayload?.lines ?? [], locale)

  const serializedCheckout: CheckoutProps = {
    ...checkout,
    products,
  }

  return {
    checkout: serializedCheckout,
    isLoading: isAbstractLoading,
    ...rest,
    rawCheckout: cartPayload,
  }
}
