import { PathRegExp, createUrl } from '@marvinh/path-to-regexp'
import { encode } from 'qss'
import processEnvPublic from 'unlikely-env/public'
import { MultistoreKeys } from '~/@types/constants'
import { DEFAULT_LOCALE } from '~/lib/constants'

import pathsMapping from 'config/paths-mapping.json'

export interface LinkResolverParams {
  type: string
  uid?: string
  lang?: string
}
type QueryParam = Record<string, string | string[]>

function getPath(
  locale: MultistoreKeys,
  type: string,
  slug: string,
  defaultSource: string,
  params: string,
) {
  const sourcePathMapping = pathsMapping?.[type]?.[locale]?.source
  const pathRegExp = new PathRegExp(
    sourcePathMapping ? sourcePathMapping : defaultSource,
  )

  return `${locale !== DEFAULT_LOCALE && `/${locale}`}/${createUrl(pathRegExp, {
    slug: slug ?? '',
  })}${params}`
}

export default function linkResolver(
  doc: any,
  locale?: MultistoreKeys,
  query?: QueryParam,
): string {
  const params = query ? `?${encode(query)}` : ''
  const pathsMapKeys = Object.keys(pathsMapping) ?? []
  let slug = doc?.full_slug
    ?.replace(`${processEnvPublic('NEXT_PUBLIC_PROJECT_NAME')}/`, '')
    ?.replace('universal_page/', '')
  if (slug?.startsWith(`${DEFAULT_LOCALE}/`))
    slug = slug.replace(`${DEFAULT_LOCALE}/`, '')

  // if we are resolving a link to a component found in the path mapping,
  // if full slug includes strictly /key/ then we should get the new path
  const docPathKey =
    pathsMapKeys.find((key) => doc?.full_slug?.includes(`/${key}/`)) ?? null

  // then we should use the path mapping functions instead of the default resolving method
  if (docPathKey) {
    return getPath(locale, docPathKey, slug?.split(`/`)?.pop(), null, params)
  }

  switch (slug) {
    default:
      return `/${slug}${params}`
  }
}
