import React from 'react'

import LoadingBar from '~/components/Abstracts/LoadingBar'

import useNextPageLoadingState from '~/hooks/useNextPageLoadingState'

export interface NextLoadingBarProps {
  className?: string
}

function NextLoadingBar({ className }: NextLoadingBarProps) {
  const loadingState = useNextPageLoadingState()
  return <LoadingBar loadingState={loadingState} className={className} />
}

NextLoadingBar.defaultProps = {}

export default NextLoadingBar
