import classnames from 'classnames/bind'
import React from 'react'

import css from './styles.module.scss'
import { ReactComponent as ArrowRight } from './svgs/arrow-right.svg'
import { ReactComponent as ArrowSelect } from './svgs/arrow-select.svg'
import { ReactComponent as Cart } from './svgs/cart.svg'
import { ReactComponent as Close } from './svgs/close.svg'
import { ReactComponent as ColorPicker } from './svgs/color-picker.svg'
import { ReactComponent as ExitFullscreen } from './svgs/exit-fullscreen.svg'
import { ReactComponent as Fullscreen } from './svgs/fullscreen.svg'
import { ReactComponent as Minus } from './svgs/minus.svg'
import { ReactComponent as Muted } from './svgs/muted.svg'
import { ReactComponent as Pause } from './svgs/pause.svg'
import { ReactComponent as Play } from './svgs/play.svg'
import { ReactComponent as Plus } from './svgs/plus.svg'
import { ReactComponent as Search } from './svgs/search.svg'
import { ReactComponent as Sound } from './svgs/sound.svg'
import { ReactComponent as Tick } from './svgs/tick.svg'

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  theme?: string
}

export function iconFromReactSvg<T>(
  Icon: React.FC<React.SVGProps<SVGSVGElement>>,
  style?: string,
): React.FC<T & IconProps> {
  const icon = ({ className, theme, ...props }: T & IconProps) => (
    <Icon
      className={classnames(style, className, css.Icon, css?.[`${theme}Theme`])}
      {...props}
    />
  )
  return icon
}

export const MutedIcon = iconFromReactSvg(Muted)
export const SoundIcon = iconFromReactSvg(Sound)
export const PlayIcon = iconFromReactSvg(Play)
export const PauseIcon = iconFromReactSvg(Pause)
export const ExitFullscreenIcon = iconFromReactSvg(ExitFullscreen)
export const FullscreenIcon = iconFromReactSvg(Fullscreen)

export const CartIcon = iconFromReactSvg(Cart)
export const CloseIcon = iconFromReactSvg(Close)
export const SearchIcon = iconFromReactSvg(Search, css.Search)
export const MinusIcon = iconFromReactSvg(Minus)
export const PlusIcon = iconFromReactSvg(Plus)
export const ArrowSelectIcon = iconFromReactSvg(ArrowSelect)
export const ArrowRightIcon = iconFromReactSvg(ArrowRight)
export const ArrowLeftIcon = iconFromReactSvg(ArrowRight, css.ArrowLeftIcon)
export const ArrowBottomIcon = iconFromReactSvg(ArrowRight, css.ArrowBottomIcon)
export const ColorPickerIcon = iconFromReactSvg(ColorPicker)
export const TickIcon = iconFromReactSvg(Tick)
