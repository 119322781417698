import { iconFromReactSvg } from '~/components/Abstracts/Icons'

import css from './styles.module.scss'
import { ReactComponent as Account } from './svgs/account.svg'
import { ReactComponent as Arrow } from './svgs/arrow.svg'
import { ReactComponent as Back } from './svgs/back.svg'
import { ReactComponent as BigLayout } from './svgs/big-layout.svg'
import { ReactComponent as Burger } from './svgs/burger.svg'
import { ReactComponent as ByCharlotCheck } from './svgs/bycharlot-check.svg'
import { ReactComponent as Cart } from './svgs/cart.svg'
import { ReactComponent as Check } from './svgs/check.svg'
import { ReactComponent as Chevron } from './svgs/chevron.svg'
import { ReactComponent as Close } from './svgs/close.svg'
import { ReactComponent as Delivery } from './svgs/delivery.svg'
import { ReactComponent as HeartEmpty } from './svgs/empty-heart.svg'
import { ReactComponent as StarEmpty } from './svgs/empty-star.svg'
import { ReactComponent as Facebook } from './svgs/facebook.svg'
import { ReactComponent as StarFull } from './svgs/full-star.svg'
import { ReactComponent as HorizontalArrow } from './svgs/horizontal-arrow.svg'
import { ReactComponent as Instagram } from './svgs/instagram.svg'
import { ReactComponent as LessThan } from './svgs/less-than.svg'
import { ReactComponent as Link } from './svgs/link.svg'
import { ReactComponent as Mail } from './svgs/mail.svg'
import { ReactComponent as Minus } from './svgs/minus.svg'
import { ReactComponent as Muted } from './svgs/muted.svg'
import { ReactComponent as Pause } from './svgs/pause.svg'
import { ReactComponent as Phone } from './svgs/phone.svg'
import { ReactComponent as Play } from './svgs/play.svg'
import { ReactComponent as Plus } from './svgs/plus.svg'
import { ReactComponent as ClosePopin } from './svgs/popin-close.svg'
import { ReactComponent as Preorder } from './svgs/preorder.svg'
import { ReactComponent as QuestionMark } from './svgs/question-mark.svg'
import { ReactComponent as Search } from './svgs/search.svg'
import { ReactComponent as SmallLayout } from './svgs/small-layout.svg'
import { ReactComponent as Sound } from './svgs/sound.svg'
import { ReactComponent as TextEdit } from './svgs/text-edit.svg'
import { ReactComponent as Ticked } from './svgs/ticked.svg'
import { ReactComponent as Trash } from './svgs/trash.svg'

export const ArrowIcon = iconFromReactSvg(Arrow, css.ArrowIcon)
export const BackIcon = iconFromReactSvg(Back, css.BackIcon)
export const TickedIcon = iconFromReactSvg(Ticked, css.TickedIcon)
export const SearchIcon = iconFromReactSvg(Search, css.SearchIcon)
export const HeartEmptyIcon = iconFromReactSvg(HeartEmpty, css.HeartEmptyIcon)
export const LeftIcon = iconFromReactSvg(LessThan, css.LeftIcon)
export const RightIcon = iconFromReactSvg(LessThan, css.RightIcon)
export const BottomIcon = iconFromReactSvg(LessThan, css.BottomIcon)
export const TopIcon = iconFromReactSvg(LessThan, css.TopIcon)
export const StarEmptyIcon = iconFromReactSvg(StarEmpty, css.StarEmptyIcon)
export const TrashIcon = iconFromReactSvg(Trash, css.TrashIcon)
export const MinusIcon = iconFromReactSvg(Minus, css.MinusIcon)
export const PlusIcon = iconFromReactSvg(Plus, css.PlusIcon)
export const StarFullIcon = iconFromReactSvg(StarFull, css.StarFullIcon)
export const TextEditIcon = iconFromReactSvg(TextEdit, css.TextEdit)
export const ChevronIcon = iconFromReactSvg(Chevron, css.Chevron)
export const PreOrderIcon = iconFromReactSvg(Preorder, css.Chevron)
export const QuestionMarkIcon = iconFromReactSvg(
  QuestionMark,
  css.QuestionMarkIcon,
)
export const HorizontalArrowIcon = iconFromReactSvg(
  HorizontalArrow,
  css.HorizontalArrowIcon,
)
export const DeliveryIcon = iconFromReactSvg(Delivery, css.DeliveryIcon)
export const CartIcon = iconFromReactSvg(Cart, css.CartIcon)
export const BurgerIcon = iconFromReactSvg(Burger, css.BurgerIcon)
export const BigLayoutIcon = iconFromReactSvg(BigLayout, css.BigLayoutIcon)
export const SmallLayoutIcon = iconFromReactSvg(
  SmallLayout,
  css.SmallLayoutIcon,
)
export const AccountIcon = iconFromReactSvg(Account, css.AccountIcon)
export const CloseIcon = iconFromReactSvg(Close, css.CloseIcon)
export const PlayIcon = iconFromReactSvg(Play, css.PlayIcon)
export const PauseIcon = iconFromReactSvg(Pause, css.PauseIcon)
export const MutedIcon = iconFromReactSvg(Muted, css.MutedIcon)
export const SoundIcon = iconFromReactSvg(Sound, css.SoundIcon)
export const LinkIcon = iconFromReactSvg(Link, css.LinkIcon)
export const MailIcon = iconFromReactSvg(Mail, css.MailIcon)
export const PhoneIcon = iconFromReactSvg(Phone, css.PhoneIcon)
export const ClosePopinIcon = iconFromReactSvg(ClosePopin, css.ClosePopinIcon)
export const CheckIcon = iconFromReactSvg(Check, css.CheckIcon)
export const BycharlotCheckIcon = iconFromReactSvg(
  ByCharlotCheck,
  css.CheckIcon,
)
export const FacebookIcon = iconFromReactSvg(Facebook, css.FacebookIcon)
export const InstagramIcon = iconFromReactSvg(Instagram, css.InstagramIcon)
