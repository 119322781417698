import classnames from 'classnames/bind'
import {
  AnimatePresence,
  m,
  useMotionTemplate,
  useMotionValue,
} from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'

import {
  useIsomorphicLayoutEffect,
  useSpring,
  useWindowSize,
} from '@unlikelystudio/react-hooks'

import { CloseIcon } from '~/components/Abstracts/Icons'

import { usePanel } from '~/providers/PanelProvider'

import useClickOutside from '~/hooks/useClickOutside'

import css from './styles.module.scss'
import { PanelManagerProps } from './types'

const cx = classnames.bind(css)

function PanelManager({ className }: PanelManagerProps) {
  const { panels, removeCurrent } = usePanel()

  const managerRef = useRef<HTMLDivElement>(null)
  const [direction, setDirection] = useState(null)

  const contentTranslateX = useMotionValue(100)
  const contentTranslateY = useMotionValue(0)
  const childrenTranslateX = useMotionValue(0)
  const childrenTranslateY = useMotionValue(0)

  const transformContentTemplate = useMotionTemplate`translate3d(${contentTranslateX}%, ${contentTranslateY}% ,0)`
  const transformChildrenTemplate = useMotionTemplate`translate3d(${childrenTranslateX}%, ${childrenTranslateY}% ,0)`

  const currentItem = panels?.length > 0 && panels?.[panels?.length - 1]

  const onEscDown = useCallback(
    (e) => {
      if (e.keyCode == 27) removeCurrent()
    },
    [removeCurrent],
  )

  useEffect(() => {
    if (currentItem?.direction) setDirection(currentItem.direction)
  }, [currentItem])

  useEffect(() => {
    window.addEventListener('keydown', onEscDown)

    return () => {
      window.removeEventListener('keydown', onEscDown)
    }
  }, [onEscDown])

  const { width } = useWindowSize()

  const [setProgressSpring] = useSpring({
    config: {
      interpolation: 'basic',
      friction: 8,
    },
    progress: 0,
    onUpdate: ({ progress }, { progress: prevProgress }) => {
      let dir

      switch (direction) {
        case 'left':
        case 'top':
          dir = -1
          break
        case 'right':
        case 'bottom':
          dir = 1
          break
        default:
          break
      }

      if (progress !== prevProgress) {
        const x = 100 - progress * 100

        if (direction === 'top' || direction === 'bottom') {
          contentTranslateX.set(0)
          // childrenTranslateX.set(0)
          contentTranslateY.set(x * dir)
          // childrenTranslateY.set(-x * dir)
        } else {
          contentTranslateX.set(x * dir)
          // childrenTranslateX.set(-x * dir)
          contentTranslateY.set(0)
          // childrenTranslateY.set(0)
        }
      }
    },
  })

  useIsomorphicLayoutEffect(() => {
    setProgressSpring({ progress: currentItem ? 1 : 0 })
  }, [currentItem])

  useClickOutside(
    managerRef,
    () => {
      currentItem?.component && currentItem.component && removeCurrent()
    },
    [currentItem],
  )

  const onClose = () => {
    removeCurrent()
  }

  return (
    <section
      className={cx(className, css.PanelManager, {
        show: currentItem,
        interactive: currentItem,
        enterFromLeft: direction === 'left',
        enterFromTop: direction === 'top',
        enterFromBottom: direction === 'bottom',
      })}
      ref={managerRef}>
      {currentItem && (
        <div className={css.closeIcon} onClick={onClose}>
          <CloseIcon theme={'black'} className={cx(css.icon)} />
        </div>
      )}
      <div
        className={cx(css.container, {
          enterFromLeft: direction === 'left',
          enterFromTop: direction === 'top',
          enterFromBottom: direction === 'bottom',
        })}>
        <AnimatePresence>
          {currentItem?.component && (
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.8 }}
              className={css.background}
              onClick={onClose}
            />
          )}
        </AnimatePresence>

        <m.div
          className={css.content}
          style={{ transform: transformContentTemplate }}>
          <m.div className={css.children}>
            <AnimatePresence>
              {currentItem?.component && currentItem.component}
            </AnimatePresence>
          </m.div>
        </m.div>
      </div>
    </section>
  )
}

PanelManager.defaultProps = {}

export default PanelManager
