import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
}

export default function useNextPageLoadingState() {
  const [loadingState, setLoadingState] = useState<
    'idle' | 'loading' | 'loaded'
  >('idle')

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (_url: string, options?: TransitionOptions) => {
      if (!options?.shallow) {
        setLoadingState('loading')
      }
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      setLoadingState('loaded')
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      setLoadingState('loaded')
    }
    router.events.on('routeChangeError', handleRouteChange)
    return () => {
      router.events.off('routeChangeError', handleRouteChange)
    }
  }, [])

  return loadingState
}
