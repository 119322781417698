import classnames from 'classnames/bind'

import { CartIcon } from '~/components/UI/Icons'

import { useGetCart } from '~/hooks/useGetCart'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface DynamicCartIconProps {
  className?: string
}

function DynamicCartIcon({ className }: DynamicCartIconProps) {
  const { rawCheckout } = useGetCart({ refetchOnMount: false })
  const withArticles = Boolean(rawCheckout?.lines?.length > 0)

  return (
    <span className={cx(className, css.DynamicCartIcon, { withArticles })}>
      <CartIcon className={css.icon} />
    </span>
  )
}

DynamicCartIcon.defaultProps = {}

export default DynamicCartIcon
