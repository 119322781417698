import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useTranslate } from 'react-polyglot'

import NavigationButtonIcon from '~/components/Navigation/Navigation/NavigationButtonIcon'
import { NavigationButton } from '~/components/Navigation/Navigation/types'
import { AccountIcon } from '~/components/UI/Icons'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function AccountButton({ className, theme, ...rest }: NavigationButton) {
  const router = useRouter()
  const t = useTranslate()
  return (
    <NavigationButtonIcon
      className={cx(className, css.CartButton)}
      onClick={() => router.push('/account')}
      aria-label={t(GENERAL.ACCOUNT)}
      theme={theme}
      {...rest}>
      <AccountIcon className={cx(css.icon)} />
    </NavigationButtonIcon>
  )
}

AccountButton.defaultProps = {}

export default AccountButton
