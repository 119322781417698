import jwtDecode from 'jwt-decode'
import processEnvPublic from 'unlikely-env/public'
import { CHECKOUT_URL, STORE_URL } from '~/lib/constants'

import { GetShopifyStorefrontClientOptions } from '@unlikelystudio/commerce-connector'

/**
 * Given a url, replace the domain name of the url with the new domain name
 * @param url - The URL of the page to be loaded.
 * @returns the url with the domain name changed.
 */
export function changeDomainUrlCheckout(url) {
  const initialDomain = STORE_URL

  if (url?.indexOf(initialDomain) > -1) {
    return url.replace(initialDomain, CHECKOUT_URL)
  }

  return url
}

/**
 * Retrieves the public credentials of a Shopify store.
 *
 * @param {boolean} withCheckoutReplaceUrl - Optional parameter that defaults to true. If true, the store URL is modified to point to 'checkout.bycharlot.fr'.
 *
 * @returns {GetShopifyStorefrontClientOptions} - An object containing the decoded token information, including the potentially modified store URL.
 *
 * @throws Will throw an error if the public token is not found.
 *
 * @example
 *
 * const credentials = getStorePublicCredentials(false);
 * console.log(credentials.storeUrl); // logs the original store URL without modification
 */
export function getStorePublicCredentials(
  withCheckoutReplaceUrl = true,
): GetShopifyStorefrontClientOptions {
  const token = processEnvPublic('NEXT_PUBLIC_SHOPIFY_TOKEN')
  const decodedToken = (
    token ? jwtDecode(token) : {}
  ) as GetShopifyStorefrontClientOptions

  if (!decodedToken) {
    throw Error('[Shopify] Public token not found')
  }

  const storeUrl = withCheckoutReplaceUrl
    ? (changeDomainUrlCheckout(
        decodedToken?.storeUrl,
      ) as GetShopifyStorefrontClientOptions['storeUrl'])
    : decodedToken?.storeUrl

  if (!decodedToken?.storeUrl || !decodedToken?.storefrontAccessToken)
    console.warn(decodedToken, '[Shopify] Missing storefront credentials')

  return {
    ...decodedToken,
    storeUrl,
  }
}
