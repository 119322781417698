import { MultistoreKeys } from '~/@types/constants'

import serializeLink from './serialize-link'

export default function serializeCta(locale: MultistoreKeys, cta) {
  return cta?.[0]
    ? {
        ...serializeLink(cta?.[0]?.url, locale),
        children: cta?.[0]?.label ?? null,
      }
    : cta
    ? {
        ...serializeLink(cta?.url, locale),
        children: cta?.label ?? null,
      }
    : null
}
