export enum GlobalThemeColors {
  White = 'white',
  Black = 'black',
  Blue = 'blue',
  Shade1 = 'shade-1',
  Shade1Darker = 'shade-1-darker',
  WhiteOnShade1 = 'white-on-shade-1',
  Shade2 = 'shade-2',
  Shade3 = 'shade-3',
  Shade4 = 'shade-4',
  Green = 'green',
  Gray1ToBlack = 'gray-1',
  Gray2ToBlack = 'gray-2',
  Gray3ToBlack = 'gray-3',
  Gray4ToBlack = 'gray-4',
  ErrorCompareAtPrice = 'error-compare-at-price',
  Transparent = 'transparent',
}

export type TThemeColors = 'white' | 'black'

export type ThemePageColors = GlobalThemeColors.Black | GlobalThemeColors.White
