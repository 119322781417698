import classnames from 'classnames'
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode, forwardRef } from 'react'

export interface LinkProps extends NextLinkProps {
  className?: string
  activeClassName?: string
  children?: ReactNode | ReactNode[]
  isExternal?: boolean
  target?: string | null
  innerRef?: any
  title?: string
}

function Link({
  className,
  children,
  href = '',
  as,
  prefetch = false,
  scroll,
  shallow,
  passHref,
  replace,
  isExternal,
  activeClassName,
  onMouseEnter,
  onClick,
  innerRef,
  ...rest
}: LinkProps) {
  const router = useRouter()
  const isActive = !isExternal && href === router?.asPath

  return !href ? (
    <div
      ref={innerRef}
      onClick={onClick}
      onMouseDown={(e) => e.preventDefault()}
      onMouseEnter={onMouseEnter}
      className={classnames(className, {
        [activeClassName]: activeClassName && isActive,
      })}
      {...rest}>
      {children}
    </div>
  ) : isExternal ? (
    <a
      ref={innerRef}
      className={className}
      href={href as string}
      onMouseDown={(e) => e.preventDefault()}
      rel="noreferrer noopener"
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      {...rest}>
      {children}
    </a>
  ) : (
    <NextLink
      legacyBehavior
      {...{
        href,
        as,
        replace,
        scroll,
        shallow,
        passHref,
        prefetch,
      }}>
      <a
        ref={innerRef}
        onMouseDown={(e) => e.preventDefault()}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        className={classnames(className, {
          [activeClassName]: activeClassName && isActive,
        })}
        {...rest}>
        {children}
      </a>
    </NextLink>
  )
}

Link.defaultProps = {}

export { default as linkResolver } from '~/lib/link-resolver'
export type { LinkResolverParams } from '~/lib/link-resolver'

function LinkForwarded(props, ref) {
  return <Link innerRef={ref} {...props} />
}

export default forwardRef<HTMLAnchorElement, LinkProps>(LinkForwarded)
