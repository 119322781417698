import Cookies from 'js-cookie'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import NextNProgress from 'nextjs-progressbar'
import { useEffect } from 'react'
import { PATHNAMES_EXCLUDED_FROM_FALLBACK_PLACEHOLDER } from '~/lib/constants'

import LoadingComponent from '~/components/Abstracts/LoadingComponent'
import NextLoadingBar from '~/components/Abstracts/NextLoadingBar'
import ThirdPartyScripts from '~/components/Abstracts/ThirdPartyScripts'
import CartAutomaticOpening from '~/components/Cart/CartAutomaticOpening'
import Page from '~/components/Page'

import AppProviders from '~/providers/AppProviders'

import useCursorLoading from '~/hooks/useCursorLoading'
import useLocale from '~/hooks/useLocale'

import waitBeforeTransition from '~/utils/wait-before-transition'

import '~/styles/globals.scss'

waitBeforeTransition()

function MyApp({ Component, pageProps }: AppProps) {
  const { pathname, isFallback, query } = useRouter()
  const key = `${pathname}${query?.uid ? query.uid : ''}`

  // Checking if the page is excluded from the fallback placeholder
  const isPathNameExcludedFromFallbackPlaceholder =
    PATHNAMES_EXCLUDED_FROM_FALLBACK_PLACEHOLDER?.includes(pathname) ?? false

  const hasData = Object.keys(pageProps)?.length !== 0

  const locale = useLocale()
  useCursorLoading()

  useEffect(() => {
    // Disable setting cookie when coming from redirect routes to get the last one filled
    if (pathname !== '/redirects/[uid]') {
      Cookies.set('NEXT_LOCALE', locale, {
        expires: 365,
      })
    }
  }, [locale])

  return (
    <AppProviders pageProps={pageProps}>
      <NextLoadingBar />
      {isFallback ? (
        <LoadingComponent />
      ) : (
        <>
          <NextNProgress color="000" showOnShallow={true} />
          <Page key={key} {...pageProps}>
            <Component {...pageProps} />
          </Page>
        </>
      )}
      <CartAutomaticOpening />
      <ThirdPartyScripts />
    </AppProviders>
  )
}

export default MyApp
