import React, { createContext, ReactNode, useContext, useMemo } from 'react'

const Context = createContext({})

function processCss(theme: any) {
  const t = {}
  for (const [key, value] of Object.entries(theme)) {
    const [map, mapKey] = key.split('___')
    if (!t[map]) t[map] = {}
    t[map][mapKey] = value
  }
  return t
}

/**
 * Export every map list declared into theme-presets to use as JS Variables like

 * @example
 * ```ts
 * const { screens, grid } = useTheme()
 * ```
 * @example
 * ```scss
 * theme-presets.module.scss
 * :export {
 *   @include mapToProps($colors, 'colors');
 *   @include mapToProps($mobile, 'mobile');
 *   @include mapToProps($desktop, 'desktop');
 *   @include mapToProps($screens, 'screens');
 * }
 * ```
 */
export function useTheme(): Record<string, any> {
  const ctx = useContext(Context)

  return useMemo(() => processCss(ctx), [ctx])
}

export interface ThemeProviderProps {
  children: ReactNode | ReactNode[]
  value: object
}

export default function ThemeProvider({ value, children }: ThemeProviderProps) {
  return <Context.Provider value={value}>{children}</Context.Provider>
}
