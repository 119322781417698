import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { GlobalThemeColors } from '~/@types/colors'

import useOnRouteChange from '~/hooks/useOnRouteChange'

export const PopInContext = createContext<PopInType>({})

interface ProviderPopInProps {
  children: ReactNode | ReactNode[]
}

export enum PopInVerticalAlignement {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export enum PopInHorizontalAlignement {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface PopInOptions {
  theme: GlobalThemeColors.Black | GlobalThemeColors.White
  withPadding: boolean
  withBackground?: boolean
  verticalAlignement?: PopInVerticalAlignement
  horizontalAlignement?: PopInHorizontalAlignement
}

export interface PopInAction {
  onClose?: () => void
  clickOutside?: boolean
}

export interface PopIns {
  content: ReactNode
  options: PopInOptions
  actions: PopInAction
}

export interface PopInType {
  popIns?: PopIns[]
  add?: (
    popIn: ReactNode,
    options?: Partial<PopInOptions>,
    actions?: Partial<PopInAction>,
  ) => void
  remove?: (popIn: ReactNode) => void
  removeCurrent?: () => void
  removeAll?: () => void
  position?: PositionPopIn
  setPosition?: React.Dispatch<React.SetStateAction<PositionPopIn>>
}

export interface PositionPopIn {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

const DEFAULT_OPTIONS: PopInOptions = {
  theme: GlobalThemeColors.Black,
  withPadding: true,
  withBackground: true,
}

export default function PopInProvider({ children }: ProviderPopInProps) {
  const [popIns, setPopIns] = useState<PopIns[]>([])
  const [position, setPosition] = useState<PositionPopIn>({})

  const add = useCallback(
    (
      popIn: ReactNode,
      options: Partial<PopInOptions> = {},
      actions: Partial<PopInAction> = { clickOutside: true },
    ) => {
      setPopIns((popIns) => [
        ...popIns,
        {
          content: popIn,
          options: {
            ...DEFAULT_OPTIONS,
            ...options,
          },
          actions,
        },
      ])
    },
    [],
  )

  const remove = useCallback((popIn: ReactNode) => {
    setPopIns((popIns) => {
      const index = popIns.findIndex((p) => p.content === popIn)
      popIns?.[index]?.actions?.onClose?.()
      if (index > -1) popIns.splice(index, 1)
      return [...popIns]
    })
  }, [])

  const removeAll = useCallback(() => {
    setPopIns([])
  }, [])

  const removeCurrent = useCallback(() => {
    setPopIns((popIns) => {
      popIns?.[popIns.length - 1]?.actions?.onClose?.()
      if (popIns.length > 0) popIns.pop()
      return [...popIns]
    })
  }, [popIns])

  useOnRouteChange({
    onRouteChange: () => {
      setPopIns([])
    },
    deps: [],
  })

  const value: PopInType = useMemo(
    () => ({
      popIns,
      add,
      remove,
      removeCurrent,
      position,
      setPosition,
      removeAll,
    }),
    [popIns, add, remove, removeAll, removeCurrent, position, setPosition],
  )

  return <PopInContext.Provider value={value}>{children}</PopInContext.Provider>
}

export function usePopIn() {
  return useContext(PopInContext)
}
