import { UnlikelyCustomAttributes } from '@unlikelystudio/commerce-connector'

export function customAttributesAsObject(
  customAttributes: UnlikelyCustomAttributes,
): { [key: string]: string } {
  return customAttributes
    .map(({ key, value }) => ({
      [key]: value,
    }))
    .reduce((obj, current) => {
      return {
        ...obj,
        ...current,
      }
    }, {})
}
