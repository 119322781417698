import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { FooterProps } from '~/@types/footer'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import Link from '~/components/Abstracts/Link'
import Newsletter from '~/components/Form/Newsletter'
import LangSwitcher from '~/components/LangSwitcher'
import Breadcrumb from '~/components/UI/Breadcrumb'
import { FooterColumn } from '~/components/UI/Footer/FooterColumn'
import { ArrowIcon, FacebookIcon, InstagramIcon } from '~/components/UI/Icons'
import Image from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function getSocialIconFromType(type) {
  const baseProps = {
    className: css.socialIcon,
  }

  switch (type) {
    case 'instagram':
      return <InstagramIcon {...baseProps} />
    case 'facebook':
      return <FacebookIcon {...baseProps} />
  }
}

function Footer({
  className,
  socialLinks,
  columns,
  newsletterTitle,
  newsletterData,
  iconLinks,
}: FooterProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const linkStyle = useStyle({ textPreset: 'cta-12-primary' })

  const { pathname } = useRouter()
  const isHomepage = pathname === '/'

  const columnTitleStyle = useStyle({
    textStyling: GlobalTextStyling.UpperCase,
    textPreset: GlobalTextPreset.Label10Primary,
  })

  return (
    <footer className={cx(css.Footer, className)}>
      <div className={cx(gridStyle, css.grid)}>
        <div className={css.column}>
          <div className={cx(css.breadcrumbContainer)}>
            <Breadcrumb className={cx({ isHomepage })} />
          </div>
          {iconLinks?.length > 0 && (
            <ul className={cx(css.iconLinks, css.linksList)}>
              {iconLinks.map(({ link, icon }, index) => (
                <li key={index} className={css.iconLinkListItem}>
                  <Link className={css.iconLink} {...link}>
                    <span className={css.iconContainer}>
                      <Image
                        layout="fill"
                        sizesFromBreakpoints={[{ ratio: 160 / 1440 }]}
                        objectFit="cover"
                        asPlaceholder
                        src={icon.src}
                      />
                    </span>
                    <div className={css.iconLinkLeftRight}>
                      <span className={cx(css.children, linkStyle)}>
                        {link.children}
                      </span>
                      <ArrowIcon className={css.arrowIcon} />
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <div className={css.footerBottom}>
            <div className={css.columnsContainer}>
              {columns?.length > 0 &&
                columns.map((column, index) => (
                  <FooterColumn
                    className={css.column}
                    titleStyle={columnTitleStyle}
                    index={index}
                    {...column}
                  />
                ))}
            </div>
            <div className={css.informationsColumn}>
              {newsletterTitle && (
                <span className={cx(css.title, columnTitleStyle)}>
                  {newsletterTitle}
                </span>
              )}
              {newsletterData && (
                <Newsletter
                  className={css.newsletter}
                  inputClassName={cx(css.newsletterInput)}
                  gdprClassName={css.optin}
                  textSubmit
                  resultText={newsletterData?.successText}
                  gdprLabel={newsletterData?.gdprText}
                  layout={'small'}
                />
              )}
              {socialLinks?.length > 0 && (
                <div className={css.socialLinks}>
                  {socialLinks.map((socialItem, index) => (
                    <Link
                      key={`social_link_${index}`}
                      aria-label={socialItem?.type}
                      className={css.socialLink}
                      {...socialItem?.link}>
                      {getSocialIconFromType(socialItem?.type)}
                    </Link>
                  ))}
                </div>
              )}
              <div className={css.footerSwitcher}>
                <LangSwitcher
                  className={css.selectLanguage}
                  selectClassName={css.languageSelect}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {}

export default Footer
