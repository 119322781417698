// PRIVATE AND DANGEROUS
const PRIVATE = {
  DESACTIVATE_PASSWORD_PROTECTION: process.env.DESACTIVATE_PASSWORD_PROTECTION,
  ALGOLIA_ADMIN_API_KEY: process.env.ALGOLIA_ADMIN_API_KEY,
  SHOPIFY_TOKEN: process.env.SHOPIFY_TOKEN_V2,
  AWS_SECRET_KEY_ID_EXPORTER: process.env.AWS_SECRET_KEY_ID_EXPORTER,
  AWS_ACCESS_KEY_ID_EXPORTER: process.env.AWS_ACCESS_KEY_ID_EXPORTER,
  RECAPTCHA_SECRET: process.env.RECAPTCHA_SECRET,
  STORYBLOK_WEBHOOK_SIGNATURE: process.env.STORYBLOK_WEBHOOK_SIGNATURE,
  STORYBLOK_TOKEN: process.env.STORYBLOK_TOKEN,
  BASIC_AUTH: process.env.BASIC_AUTH,
  VERCEL_ENV: process.env.VERCEL_ENV ?? 'development',
  NODE_ENV: process.env.NODE_ENV,
  JUDGE_ME_TOKEN: process.env.JUDGE_ME_TOKEN,
  UPSTASH_HOST: process.env.UPSTASH_HOST,
  UPSTASH_TOKEN: process.env.UPSTASH_TOKEN,
  WSA_URL: process.env.WSA_URL,
} as const

export type TPRIVATE = keyof typeof PRIVATE

/**
 * If the variable is not defined, throw an error. Otherwise, return the variable.
 * @param {TPRIVATE} variable - TPRIVATE
 */
export default function processEnvPrivate(variable: TPRIVATE) {
  const envVar = PRIVATE[variable]
  if (typeof window !== 'undefined') {
    throw new Error(
      `[${variable}] => You cannot access to a private / dangerous variable inside frontend part`,
    )
  }

  if (!envVar) {
    throw new Error(`[${variable}] => Not founded private variable`)
  }

  return envVar
}
