import AbstractImage, { ImageProps } from '~/components/Abstracts/Image'
import imageLoader from '~/components/Abstracts/Image/loader'

import css from './styles.module.scss'

function Image(props: ImageProps) {
  const { asPlaceholder, ...rest } = props

  return (
    <AbstractImage
      placeholderClassName={css.placeholder}
      asPlaceholder={rest?.priority ? false : asPlaceholder}
      loader={imageLoader}
      {...rest}
    />
  )
}

export type { ImageProps } from '~/components/Abstracts/Image'
export type { Sizes } from '~/components/Abstracts/Image/maths'
export { imageLoaderStoryblok } from '~/components/Abstracts/Image/utils'

export default Image
