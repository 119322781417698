import { RichTextBlock } from '~/components/Abstracts/RichText'

export function stringIsNotEmpty(str: string) {
  return (
    str !== '' && str !== null && str !== undefined && typeof str === 'string'
  )
}

export function isRTFilled(richText: RichTextBlock) {
  if (!richText) return false

  //@ts-ignore
  return richText?.content?.filter((item) => item.content)?.length > 0
}
