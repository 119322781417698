import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useDebouncedCallback } from 'use-debounce'

import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import { ArrowIcon } from '~/components/UI/Icons'

import { BUTTON } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const BACKTOP_TRIGGER = 400

export interface BackToTopProps {
  className?: string
}

function BackToTop({ className }: BackToTopProps) {
  const [isShown, setIsShown] = useState(false)
  const t = useTranslate()

  const onScroll = useDebouncedCallback(() => {
    if (window.scrollY > BACKTOP_TRIGGER) {
      if (!isShown) {
        setIsShown(true)
      }
    } else {
      if (isShown) {
        setIsShown(false)
      }
    }
  }, 100)

  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    setIsShown(false)
  }

  useIsomorphicLayoutEffect(() => {
    window.addEventListener('scroll', onScroll)
    onScroll()

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const variants = {
    show: { x: '0%', opacity: 1 },
    hide: { x: '30%', opacity: 0 },
  }

  return (
    <m.div
      className={cx(css.BackToTop, className)}
      initial={isShown}
      animate={isShown ? 'show' : 'hide'}
      transition={{ duration: 0.4, ease: 'easeOut' }}
      variants={variants}>
      <button
        className={css.button}
        onClick={onClick}
        aria-label={t(BUTTON.BACK_TO_TOP)}>
        <ArrowIcon className={css.icon} />
      </button>
    </m.div>
  )
}

BackToTop.defaultProps = {}

export default BackToTop
