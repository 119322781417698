import { GlobalThemeColors } from '~/@types/colors'

export function ctaNegativeTheme(theme: GlobalThemeColors): GlobalThemeColors {
  switch (theme) {
    case GlobalThemeColors.Shade1:
    case GlobalThemeColors.Shade1Darker:
    case GlobalThemeColors.White:
    case GlobalThemeColors.Transparent:
      return GlobalThemeColors.Black
    case GlobalThemeColors.Black:
      return GlobalThemeColors.White
  }
}

export function roundedCtaNegativeTheme(
  theme: GlobalThemeColors,
): GlobalThemeColors {
  return theme === GlobalThemeColors.Black
    ? GlobalThemeColors.White
    : GlobalThemeColors.Black
}

export function inlineCtaNegativeTheme(
  theme: GlobalThemeColors,
): GlobalThemeColors {
  return theme === GlobalThemeColors.Black
    ? GlobalThemeColors.White
    : GlobalThemeColors.Black
}
