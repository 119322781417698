import Head from 'next/head'
import { DEFAULT_LOCALE } from '~/lib/constants'

import { ImageProps } from '~/components/UI/Image'

import useLocale from '~/hooks/useLocale'

export interface Language {
  id: string
  uid: string
  lang: string
  url: string
  type?: string
  locale: string
}

export interface MetaProps extends Language {
  alternateLanguages: Language[]
  title?: string
  description?: string
  image?: ImageProps
}

function generateMeta(
  language: Language,
  type: 'canonical' | 'alternate' = 'canonical',
) {
  return (
    <link
      rel={type}
      href={language?.url}
      key={`${type}-${language?.locale}`}
      {...(type === 'alternate' && { hrefLang: language?.locale })}
    />
  )
}

function Meta(meta: MetaProps) {
  const locale = useLocale()
  const { title, description, image, url, alternateLanguages } = meta

  const metaTitle = title ? `${title}` : ''
  const metaDesc = description ?? null
  const metaImage = image ?? null
  const metasUrl = url ?? null

  // Get current alternate from locale
  const currentLanguage = alternateLanguages?.find(
    (item) => item.locale === locale,
  )

  // Get default alternate from locale
  const xDefault = alternateLanguages?.find(
    (item) => item.locale === DEFAULT_LOCALE,
  )

  return meta ? (
    <Head>
      <meta charSet="utf-8" />
      <title>{`${metaTitle}`}</title>
      {metaDesc && <meta name="description" content={metaDesc} />}
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content={`${metaTitle}`} key="title" />
      {metaDesc && <meta property="og:description" content={metaDesc} />}
      {metaImage && <meta property="og:image" content={metaImage?.src} />}

      {/* Generate Canonical */}
      {generateMeta(currentLanguage)}

      {alternateLanguages?.map((language) =>
        generateMeta(language, 'alternate'),
      )}
      {/* Genereate x-default alternate */}
      {generateMeta(
        {
          ...xDefault,
          lang: 'x-default',
        },
        'alternate',
      )}

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={metasUrl} />
      <meta property="twitter:title" content={`${metaTitle}`} />
      {metaDesc && <meta property="twitter:description" content={metaDesc} />}
      {metaImage && <meta property="twitter:image" content={metaImage?.src} />}
    </Head>
  ) : null
}

export default Meta
