import classnames from 'classnames/bind'
import { m, useMotionTemplate, useMotionValue } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

import { useSpring } from '@unlikelystudio/react-hooks'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LoadingBarProps {
  className?: string
  loadingState?: 'loading' | 'loaded' | 'idle'
}

function LoadingBar({ className, loadingState }: LoadingBarProps) {
  const loadingBarRef = useRef()

  const x = useMotionValue(0)
  const transformTemplate = useMotionTemplate`translate3d(${x}%, 0 ,0)`

  const [visible, setVisible] = useState(false)

  const [setProgressSpring] = useSpring({
    config: { interpolation: 'basic', friction: 50 },
    progress: 0,
    onUpdate: ({ progress }, { progress: prevProgress }) => {
      if (progress !== prevProgress) {
        x.set(progress)

        if (progress === 100 && visible) setVisible(false)
      }
    },
  })

  useEffect(() => {
    if (loadingState === 'loading') {
      setVisible(true)
      setProgressSpring({
        progress: 80,
        config: {
          friction: 50,
        },
      })
    }

    if (loadingState === 'loaded') {
      setProgressSpring({
        progress: 100,
        config: {
          friction: 8,
        },
      })
    }
  }, [loadingState, setProgressSpring])

  useEffect(() => {
    if (!visible) {
      setProgressSpring({ progress: 0, config: { friction: 6 } })
    }
  }, [visible, setProgressSpring])

  return (
    <div
      ref={loadingBarRef}
      className={cx(className, css.LoadingBar, { isVisible: visible })}>
      <m.div
        style={{ transform: transformTemplate }}
        className={css.loadingProgress}
      />
    </div>
  )
}

LoadingBar.defaultProps = {}

export default LoadingBar
