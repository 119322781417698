import classnames from 'classnames/bind'
import { ReactNode } from 'react'

import Cta from '~/components/Abstracts/Cta'
import { NavigationButton } from '~/components/Navigation/Navigation/types'

import css from './styles.module.scss'

const cx = classnames.bind(css)
export interface NavigationButtonIconProps extends NavigationButton {
  iconClassName?: string
  children?: ReactNode
}

function NavigationButtonIcon({
  className,
  theme,
  children,
  ...rest
}: NavigationButtonIconProps) {
  return (
    <Cta
      className={cx(
        className,
        css.NavigationButtonIcon,
        css?.[`${theme}Theme`],
      )}
      cx={cx}
      css={css}
      {...rest}>
      {children}
    </Cta>
  )
}

NavigationButtonIcon.defaultProps = {}

export default NavigationButtonIcon
