import { STORYBLOK_SLICES } from '~/lib/storyblok-types'

const SLICES_WITHOUT_MARGIN_TOP = [STORYBLOK_SLICES.HERO_BANNER] as const

const SLICES_WITHOUT_MARGIN_BOTTOM = [] as const

type TSLICES_WITHOUT_MARGIN_TOP = typeof SLICES_WITHOUT_MARGIN_TOP
type TSLICES_WITHOUT_MARGIN_BOTTOM = typeof SLICES_WITHOUT_MARGIN_BOTTOM

export { SLICES_WITHOUT_MARGIN_TOP, SLICES_WITHOUT_MARGIN_BOTTOM }
export type { TSLICES_WITHOUT_MARGIN_TOP, TSLICES_WITHOUT_MARGIN_BOTTOM }
