import classnames from 'classnames/bind'
import { m } from 'framer-motion'
import React from 'react'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LineProps {
  className?: string
  theme?: string
  thickness?: 1 | 2
  isVisible?: boolean
  initialLineState?: 'underlined' | 'none'
}

function Line({ className, thickness, isVisible, theme }: LineProps) {
  const variants = {
    hide: { x: '-100%' },
    show: { x: '0%' },
  }

  const animate = isVisible ? 'show' : 'hide'

  return (
    <m.span
      style={{ height: `${thickness ?? 1}px` }}
      animate={animate}
      initial={false}
      variants={variants}
      transition={{ duration: 0.4, ease: 'easeInOut' }}
      className={cx(className, css.Line, css.lineProgress, {
        [`theme-${theme}`]: theme,
      })}
    />
  )
}

Line.defaultProps = {
  theme: 'black',
  initialLineState: 'none',
  isVisible: false,
}

export default Line
