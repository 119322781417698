import classnames from 'classnames/bind'
import { ReactNode } from 'react'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { ctaNegativeTheme } from '~/lib/negative-theme-colors'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type TagThemeProps =
  | GlobalThemeColors.White
  | GlobalThemeColors.Black
  | GlobalThemeColors.Shade1
  | GlobalThemeColors.Green

export interface TagProps {
  className?: string
  label?: string
  labelClassName?: string
  withBackground?: boolean
  theme?: TagThemeProps
  children?: ReactNode
  labelColor?: TagThemeProps
  textPreset?:
    | GlobalTextPreset.Label10Secondary
    | GlobalTextPreset.Label11Secondary
    | GlobalTextPreset.Label12Secondary
    | GlobalTextPreset.Cta12PrimaryReg
}

function Tag({
  className,
  textPreset,
  label,
  labelClassName,
  withBackground,
  labelColor,
  theme,
  children,
}: TagProps) {
  const labelStyle = useStyle({
    color: labelColor ?? ctaNegativeTheme(theme),
    textPreset: textPreset,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div
      className={cx(
        css.Tag,
        className,
        { withBackground },
        css?.[`${theme}Theme`],
      )}>
      <div className={cx(css.label, labelStyle, labelClassName)}>
        {children}
        {label && <span>{label}</span>}
      </div>
    </div>
  )
}

Tag.defaultProps = {
  theme: GlobalThemeColors.Shade1,
  textPreset: GlobalTextPreset.Label11Secondary,
  withBackground: true,
}

export default Tag
