import { MultistoreKeys } from '~/@types/constants'
import linkResolver from '~/lib/link-resolver'

const defaultLink = { href: '/', rel: 'noopener' }

/**
 * Link serializer
 * @param link
 * @param locale
 * @returns Serialized link
 */
export default function serializeLink(link, locale: MultistoreKeys, query?) {
  if (link?.story?.content?.component) {
    return {
      href: linkResolver(link?.story, locale, query) ?? null,
      rel: 'noopener',
    }
  } else if (link?.content?.component) {
    return {
      href: linkResolver(link, locale, query) ?? null,
      rel: 'noopener',
    }
  } else if (link?.linktype === 'url') {
    return {
      href: link?.href ?? link?.url ?? null,
      isExternal: true,
      rel: 'noopener nofollow',
      target: '_blank',
    }
  } else {
    return defaultLink
  }
}
