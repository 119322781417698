import { useRouter } from 'next/router'
import qs from 'qs'
import { useTranslate } from 'react-polyglot'

import MockSelect from '~/components/Abstracts/Form/Select/mock'

import { useGlobalData } from '~/providers/GlobalDataProvider'

import useLocale from '~/hooks/useLocale'

import getAlternateUrl from '~/utils/alternate-url'
import { getQueryParams } from '~/utils/get-query-params'
import { removeTrailingSlash } from '~/utils/trailing-slash'

import { LANG_SWITCHER } from '~/data/dictionary'

export interface LangSwitcherProps {
  className?: string
  iconClassName?: string
  selectClassName?: string
  onClick?: () => void
  asCurrency?: boolean
  iconColor?: string
}

function LangSwitcher({
  className,
  selectClassName,
  asCurrency = true,
  onClick,
  iconColor,
  iconClassName,
}: LangSwitcherProps) {
  const router = useRouter()
  const locale = useLocale()
  const t = useTranslate()
  const { metas } = useGlobalData()

  const onSwitchLanguage = (e) => {
    const locale = e.currentTarget.value

    const selectedLang = metas?.alternateLanguages?.find(
      (altLng) => altLng?.locale === locale,
    )

    const params = qs.stringify(getQueryParams(), {
      skipNulls: true,
      arrayFormat: 'repeat',
      encode: false,
    })

    const processedParams = `${params ? `?${params}` : ''}`
    const url = selectedLang?.url
      ? `${selectedLang?.url}/${processedParams}`
      : getAlternateUrl(`/${processedParams}`)

    const pathname = removeTrailingSlash(new URL(url).pathname)
    const processedPathname = !pathname && !params ? '/' : pathname

    router.replace(
      `${processedPathname}${params ? '?' : ''}${params}`,
      undefined,
      { locale },
    )
  }

  const locales = router?.locales
  const options = locales?.map((lang) => {
    const langage = t(LANG_SWITCHER.LANGUAGE(lang))
    const symbol = t(LANG_SWITCHER.LANGUAGE_SYMBOL(lang))

    return {
      value: lang,
      label: `${langage}${asCurrency ? ` (${symbol})` : ''}`,
    }
  })

  return (
    <div className={className} onClick={onClick}>
      <MockSelect
        onChange={onSwitchLanguage}
        defaultValue={locale}
        selectClassName={selectClassName}
        options={options}
        iconClassName={iconClassName}
        iconColor={iconColor}
      />
    </div>
  )
}

export default LangSwitcher
