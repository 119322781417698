import Cookies from 'js-cookie'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'

import useLocale from '~/hooks/useLocale'

export const BannerContext = createContext<BannerType>({})

export function useBanner() {
  return useContext(BannerContext)
}

interface BannerType {
  hide?: () => void
  isVisible?: boolean
}

export interface BannerProviderProps {
  children?: ReactNode | ReactNode[]
}

function getInitialCookieState(locale) {
  const cookieValue = Cookies?.get(`${locale}-is-banner-visible`) ?? true

  return cookieValue !== '0'
}

export default function BannerProvider({ children }: BannerProviderProps) {
  const [isVisible, setIsVisible] = useState(false)
  const locale = useLocale()

  useIsomorphicLayoutEffect(() => {
    const firstLoadVisible = getInitialCookieState(locale)
    setIsVisible(firstLoadVisible)
  }, [])

  const hide = useCallback(() => {
    Cookies.set(`${locale}-is-banner-visible`, '0', { expires: 365 })
    setIsVisible(false)
  }, [])

  const values: BannerType = useMemo(
    () => ({
      hide,
      isVisible,
    }),
    [isVisible, hide],
  )

  return (
    <BannerContext.Provider value={values}>{children}</BannerContext.Provider>
  )
}
