import { ErrorOption } from 'react-hook-form'

import useCustomerErrors from '~/hooks/account/useCustomerErrors'
import useAlerts from '~/hooks/useAlerts'

export default function useOnMutationError(
  setError: (name?: string, error?: ErrorOption) => void,
) {
  const triggerAlert = useAlerts()
  const onError = useCustomerErrors()

  const handleMutationError = (err, rest, callback) => {
    if (!(Object.keys(err)?.length > 0)) triggerAlert('SERVER_ERRROR')
    const { errsGlobals } = onError(err, setError)
    for (const [, val] of Object.entries(errsGlobals)) triggerAlert(val)
    callback?.(err, ...rest)
  }

  return handleMutationError
}
