import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { inlineCtaNegativeTheme } from '~/lib/negative-theme-colors'

import Cta, { BaseProps } from '~/components/Abstracts/Cta'
import Line, { LineProps } from '~/components/Abstracts/Line'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type InlineCtaTextStyling = GlobalTextStyling.UpperCase

export type InlineCtaTextPreset =
  | GlobalTextPreset.Cta12Primary
  | GlobalTextPreset.Cta12PrimaryReg
  | GlobalTextPreset.Cta10Primary

export type InlineCtaColors =
  | GlobalThemeColors.White
  | GlobalThemeColors.Black
  | GlobalThemeColors.Shade1
  | GlobalThemeColors.Gray1ToBlack
  | GlobalThemeColors.Gray2ToBlack

export interface InlineCtaProps extends BaseProps {
  className?: string
  theme?: InlineCtaColors
  textPreset?: InlineCtaTextPreset
  textStyling?: InlineCtaTextStyling
  lineProps?: LineProps
  active?: boolean
  ref?: React.Ref<HTMLAnchorElement | HTMLButtonElement>
}

function InlineCta({
  className,
  children,
  lineProps,
  textPreset,
  textStyling,
  active,
  ...rest
}: InlineCtaProps) {
  const props = {
    css,
    cx,
    negativeColor: inlineCtaNegativeTheme(rest.theme),
  }

  const textStyle = useStyle({
    textPreset,
    color: rest.theme,
    textStyling: textStyling ?? null,
  })

  return (
    <Cta
      className={cx(
        className,
        textStyle,
        css.InlineCta,
        { noPadding: lineProps.isVisible === false },
        css?.[rest.theme],
      )}
      href={null}
      {...props}
      {...rest}>
      {({ isHover }) => (
        <>
          <Line
            className={cx(css.line, rest.theme)}
            theme={rest.theme}
            isVisible={
              lineProps.initialLineState === 'underlined' || active
                ? !isHover
                : isHover
            }
            {...lineProps}
          />
          {children}
        </>
      )}
    </Cta>
  )
}

InlineCta.defaultProps = {
  textPreset: GlobalTextPreset.Cta12Primary,
  theme: GlobalThemeColors.Black,
  lineProps: {
    acumulator: 1,
    initialLineState: 'underlined',
  },
}

export default InlineCta
