import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import AbstractCheckbox, {
  CheckboxProps as AbstractCheckboxProps,
} from '~/components/Abstracts/Form/Checkbox'
import {
  TCheckboxPresets,
  useCheckboxPreset,
} from '~/components/Abstracts/Form/Checkbox/hooks'
import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import { CheckIcon } from '~/components/CheckIcon'
import ErrorList from '~/components/Form/ErrorList'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type CheckBoxTheme = GlobalThemeColors.Black | GlobalThemeColors.White
type CheckBoxTextPreset =
  | GlobalTextPreset.Label12Secondary
  | GlobalTextPreset.Label10Primary

export interface CheckboxProps extends Omit<AbstractCheckboxProps, 'label'> {
  className?: string
  abstractClassName?: string
  label?: RichTextBlock | string
  theme?: CheckBoxTheme
  preset?: TCheckboxPresets
  textPreset?: CheckBoxTextPreset
  isSwitch?: boolean
}

function Checkbox({
  className,
  preset,
  theme,
  textPreset,
  isSwitch,
  abstractClassName,
  ...rest
}: CheckboxProps) {
  const defaultTextPreset = GlobalTextPreset.Title10Primary
  const defaultColorPreset = GlobalThemeColors.Black
  const labelStyle = useStyle({
    color: theme ?? defaultColorPreset,
    textPreset: textPreset ?? defaultTextPreset,
  })

  const { label, ...props } = useCheckboxPreset(preset, rest)
  return (
    <div
      className={cx(
        css.Checkbox,
        className,
        { isSwitch },
        css?.[`theme-${theme}`],
      )}>
      <AbstractCheckbox
        className={abstractClassName}
        errorClassname={cx(css.error)}
        checkmarkClassName={cx(css.checkmark)}
        contentClassName={cx(css.content)}
        checkmarkActiveClassName={cx(css.checkmarkActive)}
        labelClassName={cx(css.label, labelStyle)}
        label={typeof label === 'string' ? label : <RichText render={label} />}
        checkmarkActive={<CheckIcon className={css.checkIcon} />}
        {...props}
      />
      <ErrorList className={cx(css.errorList)} name={props.name} />
    </div>
  )
}

Checkbox.defaultProps = {
  isSwitch: false,
}

export default Checkbox
