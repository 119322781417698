import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { ctaNegativeTheme } from '~/lib/negative-theme-colors'

import Cta, { BaseProps } from '~/components/Abstracts/Cta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type BlurredCtaTextPreset =
  | GlobalTextPreset.Cta10Primary
  | GlobalTextPreset.Cta12Primary

export type BlurredCtaColors =
  | GlobalThemeColors.White
  | GlobalThemeColors.Black
  | GlobalThemeColors.Shade1
  | GlobalThemeColors.Transparent

export interface BlurredCtaProps extends BaseProps {
  className?: string
  theme?: BlurredCtaColors
  textPreset?: BlurredCtaTextPreset
  isBig?: boolean
  form?: string
  download?: boolean
}

function BlurredCta({
  className,
  textPreset,
  isBig,
  ...rest
}: BlurredCtaProps) {
  const props = {
    css,
    cx,
    negativeColor: ctaNegativeTheme(rest.theme),
  }

  const textStyle = useStyle({
    textPreset,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <Cta
      className={cx(className, textStyle, css.BlurredCta, {
        isBig,
      })}
      {...props}
      {...rest}
    />
  )
}

BlurredCta.defaultProps = {
  theme: GlobalThemeColors.Shade1,
  textPreset: GlobalTextPreset.Cta12Primary,
  withBackground: true,
  withBorder: false,
  withHover: true,
}

export default BlurredCta
