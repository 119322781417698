import classnames from 'classnames'
import React, {
  createContext,
  DependencyList,
  ReactNode,
  useContext,
  useMemo,
} from 'react'

import { stylePresets } from '~/providers/AppProviders'

const Context = createContext({})

export interface StyleProviderProps {
  children: ReactNode | ReactNode[]
  value: typeof stylePresets | typeof stylePresets[]
}

export type UseStyleOptions = {
  [key in keyof StyleProviderProps['value']]?: string
}

function StyleProvider({ value, children }: StyleProviderProps) {
  return <Context.Provider value={value}>{children}</Context.Provider>
}

StyleProvider.defaultProps = {}

export function useStyle(options: UseStyleOptions, deps?: DependencyList) {
  const ctx = useContext(Context)

  return useMemo(
    () =>
      classnames(
        Object.keys(options)
          ?.map((key) => {
            const value = options?.[key]
            const isArray = Array.isArray(value)

            return isArray
              ? value?.map((val) => ctx?.[key]?.[val])
              : ctx?.[key]?.[options?.[key]] ?? ''
          })
          .flat(2) ?? '',
      ),
    deps || [options],
  )
}

export default StyleProvider
