import { UnlikelyCartLine } from '~/@types/cart'
import { Nullish } from '~/@types/generic'
import { productVariantMetafields } from '~/lib/shopify/constants/metafields'
import { metafieldsToObject } from '~/lib/shopify/utils/metafield-to-object'

import { UnlikelyCart } from '@unlikelystudio/commerce-connector'

/**
 * This function calculates the quantity correction needed for updated line items.
 *
 * It takes two parameters: the original line items and the updated line items. Both are arrays of line items.
 * A line item is an object that represents a product in the cart, with properties such as id, quantity, and variantId.
 *
 * The function iterates over the original line items and for each one, it finds the corresponding updated line item.
 * If it finds a match, it subtracts the original quantity from the updated quantity to get the quantity correction.
 * If it doesn't find a match, it means the line item has been removed, so the quantity correction is the negative of the original quantity.
 *
 * The function returns an object where the keys are the line item ids and the values are the quantity corrections.
 *
 * @param {Array} originalLineItems - The original line items before the update.
 * @param {Array} updatedLineItems - The updated line items after the update.
 *
 * @returns {number} - The new quantity for cart lines.
 *
 * @example
 *
 * const originalCart: UnlikelyCart = {
 *   id: 'gid://shopify/xxx/123',
 *   lines: [
 *     { id: 'gid://shopify/xxx/1', quantity: 2, merchandise: { id: 'gid://shopify/ProductVariant/m1', metafields: [] } },
 *     { id: 'gid://shopify/xxx/2', quantity: 3, merchandise: { id: 'gid://shopify/ProductVariant/m2', metafields: [] } }
 *   ]
 * };
 * const updatedItems: UnlikelyCartLineUpdateInput[] = [
 *   { merchandiseId: 'gid://shopify/ProductVariant/m1', quantity: 3 },
 *   { merchandiseId: 'gid://shopify/ProductVariant/m3', quantity: 1 }
 * ];
 * const total = getQuantityCorrectionFromUpdatedLineItems(originalCart, updatedItems);
 * console.log(corrections); // logs [{ id: 'gid://shopify/CartLine/1', quantity: 1, merchandise: { id: 'gid://shopify/ProductVariant/m1', metafields: [] } }, { id: 'gid://shopify/CartLine/2', quantity: 3, merchandise: { id: 'gid://shopify/ProductVariant/m2', metafields: [] } }]
 * console.log(total); // logs 1
 */
export function getQuantityCorrectionFromUpdatedLineItems(
  cart: UnlikelyCart,
  updatedItems: UnlikelyCartLine[],
): Nullish<number> {
  let lowestQuantityAvailable = Infinity
  let bundleMismatchQuantity = false
  const updatedCartLines = []

  for (const line of cart.lines) {
    const updatedItem = updatedItems.find((item) => line?.id === item?.id)
    if (updatedItem) {
      updatedCartLines.push(line)
      if (line.quantity !== updatedCartLines[0].quantity) {
        bundleMismatchQuantity = true
      }
      // This condition checks if the inventory of the product variant associated with the line item is being tracked.
      if (
        metafieldsToObject(
          line.merchandise.metafields,
          productVariantMetafields,
        )?.variantInventoryIsTracked !== 'true'
      ) {
        lowestQuantityAvailable = Math.min(
          lowestQuantityAvailable,
          line.quantity,
        )
      }
    }
  }

  if (bundleMismatchQuantity && updatedCartLines.length > 0) {
    return lowestQuantityAvailable
  }
  return null
}
