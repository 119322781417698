import Script from 'next/script'
import { GTM_ID } from '~/lib/constants'

function ThirdPartyScripts() {
  return (
    <>
      <Script
        id="gtm"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://tr.bycharlot.com/x16thomrmtm921a.js?aw='+i.replace(/^GTM-/, '')+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');`,
        }}
      />

      {/* Polyfill */}
      <Script
        key="polyfill"
        strategy="beforeInteractive"
        src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=Math.hypot%2CObject.assign%2CObject.keys%2CArray.prototype.findIndex%2CArray.prototype.find%2CrequestAnimationFrame%2CIntersectionObserver%2CResizeObserver%2CObject.entries"
      />
    </>
  )
}

export default ThirdPartyScripts
