// PUBLIC
const PUBLIC = {
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
  NEXT_PUBLIC_COOKIEBOT_ID: process.env.NEXT_PUBLIC_COOKIEBOT_ID,
  NEXT_PUBLIC_RECAPTCHA_API_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_API_KEY,
  NEXT_PUBLIC_PROJECT_NAME: process.env.NEXT_PUBLIC_PROJECT_NAME,
  NEXT_PUBLIC_ALGOLIA_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  NEXT_PUBLIC_ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  NEXT_PUBLIC_MAINTENANCE_MODE: process.env.NEXT_PUBLIC_MAINTENANCE_MODE,
  NEXT_PUBLIC_DISABLE_JUDGEME: process.env.NEXT_PUBLIC_DISABLE_JUDGEME,
  NEXT_PUBLIC_SHOPIFY_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_TOKEN_V2,
  NEXT_PUBLIC_COOKIE_PRO_ID: process.env.NEXT_PUBLIC_COOKIE_PRO_ID,
  NEXT_PUBLIC_STORYBLOK_TOKEN: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  NEXT_PUBLIC_ENABLE_AUTH: process.env.NEXT_PUBLIC_ENABLE_AUTH,
  NEXT_PUBLIC_JUDGE_ME: process.env.NEXT_PUBLIC_JUDGE_ME,
  NEXT_PUBLIC_HIDE_ACCOUNT: process.env.NEXT_PUBLIC_HIDE_ACCOUNT,
} as const

export type TPUBLIC = keyof typeof PUBLIC

/**
 * It takes a variable name as a string, and returns the value of that variable from the PUBLIC object
 * @param {TPUBLIC} variable - TPUBLIC
 * @returns The value of the variable in the PUBLIC object.
 */
export default function processEnvPublic(variable: TPUBLIC) {
  const envVar = PUBLIC[variable]

  if (!envVar) {
    throw new Error(`[${variable}] => Not founded public variable`)
  }

  return envVar
}
