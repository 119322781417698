import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { QUERY_PARAMS } from '~/lib/constants'

import { usePanel } from '~/providers/PanelProvider'

const PanelCart = dynamic(() => import('~/components/Panels/CartPanelWrapped'))

export function useCartAutomaticOpening() {
  const { push, ...router } = useRouter()
  const needToOpenCart =
    router.query?.[QUERY_PARAMS?.CART?.key] === QUERY_PARAMS.CART.value

  const { add } = usePanel()
  const openPanel = () => {
    add({
      component: <PanelCart />,
    })
  }

  useEffect(() => {
    if (needToOpenCart) {
      // remove search query
      const { cart, ...restQuery } = router.query
      push(
        {
          ...router,
          query: restQuery,
        },
        null,
        { shallow: true },
      ).then(() =>
        // open cart
        openPanel(),
      )
    }
  }, [needToOpenCart])
}
