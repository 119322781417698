import classnames from 'classnames/bind'
import dynamic from 'next/dynamic'
import { useTranslate } from 'react-polyglot'

import NavigationButtonIcon from '~/components/Navigation/Navigation/NavigationButtonIcon'
import { NavigationButton } from '~/components/Navigation/Navigation/types'

import { usePanel } from '~/providers/PanelProvider'

import { BUTTON } from '~/data/dictionary'

import DynamicCartIcon from '../../DynamicCartIcon'
import css from './styles.module.scss'

const PanelCart = dynamic(() => import('~/components/Panels/CartPanelWrapped'))

const cx = classnames.bind(css)

function CartButton({ className, theme, ...rest }: NavigationButton) {
  const { add: addPanel } = usePanel()
  const t = useTranslate()
  const openPanel = () => {
    addPanel({
      component: <PanelCart />,
    })
  }

  return (
    <NavigationButtonIcon
      className={cx(className, css.CartButton)}
      onClick={openPanel}
      aria-label={t(BUTTON.OPEN_CART)}
      theme={theme}
      {...rest}>
      <DynamicCartIcon className={cx(css.icon)} />
    </NavigationButtonIcon>
  )
}

CartButton.defaultProps = {}

export default CartButton
