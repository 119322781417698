import React, {
  createContext,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'

interface CartProviderContextState {
  isOpen: boolean
}
export type CartProviderContext = [
  CartProviderContextState,
  React.Dispatch<SetStateAction<CartProviderContextState>>,
]

const CartContext = createContext<CartProviderContext>(null)

export function useCartContext() {
  return useContext<CartProviderContext>(CartContext)
}

export interface CartProviderProps {
  children: ReactNode | ReactNode[]
}

export default function CartPanelProvider({ children }: CartProviderProps) {
  const useCartState = useState<CartProviderContextState>({
    isOpen: false,
  })
  return (
    <CartContext.Provider value={useCartState}>{children}</CartContext.Provider>
  )
}
