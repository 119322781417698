import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'

import NavigationButtonIcon from '~/components/Navigation/Navigation/NavigationButtonIcon'
import { NavigationButton } from '~/components/Navigation/Navigation/types'
import { CloseIcon } from '~/components/UI/Icons'

import { BUTTON } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)
function CloseButton({ className, theme, ...rest }: NavigationButton) {
  const t = useTranslate()
  return (
    <NavigationButtonIcon
      className={className}
      aria-label={t(BUTTON.CLOSE_MENU)}
      theme={theme}
      {...rest}>
      <CloseIcon className={cx(css.icon)} />
    </NavigationButtonIcon>
  )
}

CloseButton.defaultProps = {}

export default CloseButton
