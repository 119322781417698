import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const NavigationContext = createContext<ValueType>({})

interface NavigationProviderProps {
  value: any
  children: ReactNode | ReactNode[]
}

export type NavigationType = Record<string, any>

export interface ValueType {
  navigation?: NavigationType
  update?: (id: string, value: any) => void
}

export default function NavigationProvider({
  value,
  children,
}: NavigationProviderProps) {
  const [navigation, setNavigation] = useState({})

  const update = useCallback(
    (id, val) => {
      setNavigation((navigation) => ({ ...navigation, [id]: val }))
    },
    [setNavigation],
  )

  const processedValue: ValueType = useMemo(
    () => ({
      navigation: { ...value, ...navigation },
      update,
    }),
    [navigation, value, update],
  )

  return (
    <NavigationContext.Provider value={processedValue}>
      {children}
    </NavigationContext.Provider>
  )
}

export function useNavigation() {
  return useContext(NavigationContext)
}
