import { useTranslate } from 'react-polyglot'
import { AlertPreset } from '~/@types/alert'

import { useAlertContext } from '@unlikelystudio/react-abstract-components'

import { ALERT } from '~/data/dictionary'

export default function useAlerts() {
  const { triggerAlert } = useAlertContext()
  const t = useTranslate()

  const getPreset = (preset: AlertPreset) => {
    switch (preset) {
      case 'ENTITY_SAVED':
      case 'EMAIL_SENT':
      case 'ALERT_STOCK_SUCCESS':
      case 'REVIEW_SUBMITTED':
      case 'NEWSLETTER_SUBSCRIBE':
        return {
          title: t(ALERT.SUCCESS_TITLE),
          message: t(ALERT?.[preset] ?? preset),
          type: 'success',
        }
      default:
        return {
          title: t(ALERT.ERROR_TITLE),
          message: t(ALERT?.[preset] ?? preset),
          type: 'error',
        }
    }
  }

  return (preset?: AlertPreset) => {
    triggerAlert(getPreset(preset))
  }
}
