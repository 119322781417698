import classnames from 'classnames/bind'
import { TThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import AbstractLangSwitcher, {
  LangSwitcherProps as AbstractLangSwitcherProps,
} from '~/components/Abstracts/LangSwitcher'

import { useStyle } from '~/providers/StyleProvider'

import useTextColorFromTheme from '~/hooks/useTextColorFromTheme'

import css from './styles.module.scss'

export type { LangSwitcherProps } from '~/components/Abstracts/LangSwitcher'

const cx = classnames.bind(css)

interface LangSwitcherProps extends AbstractLangSwitcherProps {
  theme?: TThemeColors
  hasScrolled?: boolean
}

function LangSwitcher({
  className,
  selectClassName,
  theme,
  hasScrolled,
  ...rest
}: LangSwitcherProps) {
  const { textColor: color } = useTextColorFromTheme()
  const componentColor = hasScrolled ? color : theme
  const selectStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <AbstractLangSwitcher
      className={cx(css.LangSwitcher, className)}
      selectClassName={cx(css.Select, selectStyle, selectClassName)}
      asCurrency={false}
      iconClassName={cx(css.icon)}
      iconColor={componentColor}
      {...rest}
    />
  )
}

LangSwitcher.defaultProps = {}

export default LangSwitcher
