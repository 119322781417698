import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import {
  ReactNode,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'

import { ClosePopinIcon } from '~/components/UI/Icons'

import { useStyle } from '~/providers/StyleProvider'

import { BUTTON } from '~/data/dictionary'

import RichText, { RichTextBlock } from '../../../Abstracts/RichText'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface NavBannerProps {
  className?: string
  backgroundColor?: string
  texts?: RichTextBlock[]
  theme: GlobalThemeColors
  innerRef?: any
}

const NavBannerContext = createContext(null)
export interface NavBannerContextProviderProps {
  children?: ReactNode | ReactNode[]
}
export function NavBannerContextProvider({
  children,
}: NavBannerContextProviderProps) {
  const useBannerState = useState(true)
  return (
    <NavBannerContext.Provider value={useBannerState}>
      {children}
    </NavBannerContext.Provider>
  )
}

export function useNavBannerContext() {
  return useContext(NavBannerContext)
}

function NavBanner({
  className,
  texts,
  backgroundColor,
  theme,
  innerRef,
}: NavBannerProps) {
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.RichTextBanner,
  })
  const [index, setIndex] = useState(0)
  const [isVisible, setIsVisible] = useNavBannerContext()
  const t = useTranslate()
  useEffect(() => {
    const autoIncrementValues = () => {
      setIndex((prevIndex) => {
        const maxValue = texts?.length - 1
        let newCurrent = null
        if (prevIndex < maxValue && prevIndex >= 0) {
          newCurrent = prevIndex + 1
        } else {
          newCurrent = 0
        }
        return newCurrent
      })
    }
    const interval = setInterval(autoIncrementValues, 6000)
    return () => {
      clearInterval(interval)
    }
  }, [texts, setIndex])

  const themeStyle = useStyle({ color: theme })
  const gridStyle = useStyle({ grid: GlobalGridPreset.NAV_GRID })

  return texts ? (
    <div
      ref={innerRef}
      style={{ backgroundColor }}
      className={cx(css.NavBanner, themeStyle, className)}>
      <div className={cx(gridStyle, css.grid)}>
        <div className={css.slider}>
          <AnimatePresence>
            <m.span
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: 0.25, duration: 0.25 },
              }}
              exit={{ opacity: 0, transition: { duration: 0.25 } }}
              className={cx(css.textWrapper)}
              key={`text_item_${index}`}>
              <RichText
                render={texts[index]}
                className={cx(textStyle, css.text)}
              />
            </m.span>
          </AnimatePresence>
        </div>
        <button
          onClick={() => {
            setIsVisible(false)
          }}
          aria-label={t(BUTTON.CLOSE)}
          className={cx(css.closeButton)}>
          <ClosePopinIcon theme={theme} className={cx(css.icon)} />
        </button>
      </div>
    </div>
  ) : null
}

NavBanner.defaultProps = {}

function BannerForwarded(props, ref) {
  return <NavBanner innerRef={ref} {...props} />
}

export default forwardRef<any, NavBannerProps>(BannerForwarded)
