import { useTranslate } from 'react-polyglot'

import { CheckboxProps } from '~/components/Abstracts/Form/Checkbox'
import { RichTextBlock } from '~/components/Abstracts/RichText'

import { FORM } from '~/data/dictionary'

const checkboxPresets = {
  isDefaultAddress: {
    required: false,
    name: 'is-default-address',
    id: 'is-default-address',
    label: FORM.DEFAULT_ADDRESS,
  },
  acceptMarketing: {
    required: false,
    name: 'accept-marketing',
    id: 'accept-marketing',
    label: FORM.ACCEPT_MARKETING,
  },
} as const

export type TCheckboxPresets = keyof typeof checkboxPresets

export default function useCheckboxPreset<
  T extends Omit<CheckboxProps, 'label'> & { label?: string | RichTextBlock },
>(
  key: TCheckboxPresets,
  args: T,
): Partial<T> & { label?: string | RichTextBlock } {
  const t = useTranslate()
  const currentPreset = checkboxPresets?.[key]

  if (!checkboxPresets[key]) return args

  return {
    ...currentPreset,
    label: args?.label || t(currentPreset?.label),
    ...args,
  }
}
