import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import React, {
  MouseEventHandler,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { GlobalTextPreset } from '~/@types/text-preset'

import { useNavBannerContext } from '~/components/Navigation/Navigation/NavBanner'

import { useStyle } from '~/providers/StyleProvider'

import useBreakpoint from '~/hooks/useBreakpoint'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ToastProps {
  className?: string
  children?: ReactNode | ReactNode[]
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  uid: string
}

export interface ToastProviderProps {
  children?: ReactNode | ReactNode[]
}

const ToastContext = createContext([])
const SetToastContext =
  createContext<React.Dispatch<React.SetStateAction<any>>>(null)

export function ToastProvider({ children }: ToastProviderProps) {
  const [alerts, setAlerts] = useState([])
  return (
    <ToastContext.Provider value={alerts}>
      <SetToastContext.Provider value={setAlerts}>
        {children}
      </SetToastContext.Provider>
    </ToastContext.Provider>
  )
}

const ease = {
  type: 'ease',
  ease: 'easeInOut',
  duration: 1.1,
}

export const MOTION_TOAST_TRANSITION = {
  type: 'spring',
  damping: 25,
  stiffness: 150,
  layoutY: {
    duration: 0.8,
    ...ease,
  },
}

export function Toast({
  className,
  children,
  onMouseEnter,
  onMouseLeave,
}: ToastProps) {
  const [withBanner] = useNavBannerContext()

  const textStyle = useStyle({ textPreset: GlobalTextPreset.Cta12Primary })
  const isMobile = useBreakpoint('md')

  const toastVariant = {
    initialOpacity: { opacity: 0 },
    animateOpacity: {
      opacity: 1,
    },
    initial: { y: isMobile ? -64 : null, x: isMobile ? null : '100%' },
    animate: {
      y: isMobile ? 0 : null,
      x: isMobile ? null : '0%',
    },
    exit: {
      opacity: 0,
      transition: {
        delay: 0,
        ease: 'easeIn',
        duration: 0.2,
      },
    },
  }

  return (
    <m.li
      variants={toastVariant}
      initial={['initialOpacity', 'initial']}
      animate={['animateOpacity', 'animate']}
      exit="exit"
      layout="position"
      transition={MOTION_TOAST_TRANSITION}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(css.Toast, className, textStyle, { withBanner })}>
      {children}
    </m.li>
  )
}

export interface ToastCenterProps {
  className?: string
}
export function ToastCenter({ className }: ToastCenterProps) {
  const alerts = useContext(ToastContext)
  const setAlerts = useContext(SetToastContext)

  useEffect(() => {
    return () => {
      setAlerts([])
    }
  }, [])

  return (
    <m.ul
      className={cx(css.ToastCenter, className, {
        hasToasts: !!(alerts?.length > 0),
      })}>
      <li className={cx(css.grid)}>
        <AnimatePresence>
          {alerts?.map?.((alert: ToastProps, index) => {
            return <Toast key={index} {...alert} />
          })}
        </AnimatePresence>
      </li>
    </m.ul>
  )
}

export function useToast() {
  const setAlerts = useContext(SetToastContext)
  const debouncedCloseAlert = useDebouncedCallback((uid: string) => {
    setAlerts((alerts: ToastProps[]) =>
      alerts?.filter((alert) => alert.uid !== uid),
    )
  }, 3000)

  useEffect(() => {
    setTimeout(() => {
      debouncedCloseAlert.flush()
    }, 4000)
  }, [debouncedCloseAlert])

  return (toastProps: ToastProps) => {
    setAlerts((alerts: ToastProps[]) => {
      return alerts?.find((alert) => alert.uid === toastProps.uid)
        ? alerts
        : [...(alerts ? alerts : []), toastProps]
    })

    debouncedCloseAlert.flush()
    debouncedCloseAlert(toastProps.uid)
  }
}
