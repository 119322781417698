import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import Tag, { TagProps as BaseProps } from '~/components/Abstracts/Tag'

import { TAG } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface TagCustomizableProps extends BaseProps {
  className?: string
  isShrunk?: boolean
}

function TagCustomizable({
  className,
  isShrunk,
  ...rest
}: TagCustomizableProps) {
  const t = useTranslate()

  return (
    <Tag
      {...rest}
      theme={GlobalThemeColors.White}
      className={cx(css.TagCustomizable, className, { isShrunk })}
      labelClassName={css.label}
      textPreset={GlobalTextPreset.Label10Secondary}
      label={t(TAG.CUSTOMIZABLE)}>
      <span className={css.wheelIcon}></span>
    </Tag>
  )
}

TagCustomizable.defaultProps = {
  isShrunk: false,
  theme: GlobalThemeColors.Shade1,
}

export default TagCustomizable
