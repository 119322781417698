import processEnvPublic from 'unlikely-env/public'
import { Nullish } from '~/@types/generic'

import { removeTrailingSlash } from '~/utils/trailing-slash'

export default function getAlternateUrl(url: Nullish<string>) {
  if (!url) {
    return processEnvPublic('NEXT_PUBLIC_VERCEL_URL') ?? ''
  }

  return removeTrailingSlash(
    `${processEnvPublic('NEXT_PUBLIC_VERCEL_URL') ?? ''}${url}`,
  )
}
