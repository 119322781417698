import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'

const Context = createContext<ValueType>({})

export interface ValueType {
  hoverLink?: ItemBounds
  setHoverLink?: React.Dispatch<React.SetStateAction<ItemBounds>>
  topLinksBounds?: ItemBounds
  setTopLinksBounds?: React.Dispatch<React.SetStateAction<ItemBounds>>
}

export interface ItemBounds {
  width?: number
  height?: number
  left?: number
  bottom?: number
  top?: number
  right?: number
  x?: number
  y?: number
}

export function useNavPanelLinks() {
  return useContext(Context)
}

export interface NavPanelLinksProps {
  children?: ReactNode | ReactNode[]
}

function NavPanelLinks({ children }: NavPanelLinksProps) {
  const [hoverLink, setHoverLink] = useState(null)
  const [topLinksBounds, setTopLinksBounds] = useState(null)

  const providerValue = useMemo(
    () => ({
      hoverLink,
      setHoverLink,
      topLinksBounds,
      setTopLinksBounds,
    }),
    [hoverLink, topLinksBounds],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

NavPanelLinks.defaultProps = {}

export default NavPanelLinks
