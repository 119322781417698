export const ALGOLIA_HITS_PER_PAGE = 32
export const ALGOLIA_PRODUCT_INDEX = 'horizon_product'
export const ALGOLIA_SHOPIFY_ID_PROPERTY_KEY = 'objectID'
export const ALGOLIA_STORYBLOK_ID_PROPERTY_KEY = 'storyID'

export const REPLICAS_SUFFIX = {
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc',
  DEFAULT: '',
}

export const FACETS = {
  [ALGOLIA_STORYBLOK_ID_PROPERTY_KEY]: ALGOLIA_STORYBLOK_ID_PROPERTY_KEY,
  EXPRESS_DELIVERY: 'expressDelivery',
  CATEGORIES: 'categories',
  VERTUS: 'vertus',
  COLORS: 'colors',
  SIGNIFICATIONS: 'significations',
  MATERIALS: 'materials',
  PLANT_TYPE: 'plantType',
  PRODUCT_TYPE: 'productType',
  PLANT_SIZE: 'plantSize',
  BRAND: 'brand',
  PLANT_EXPOSURES: 'plantExposures',
  PRODUCT_ENVIRONMENTS: 'productEnvironments',
}
