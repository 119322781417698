import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { GlobalThemeColors } from '~/@types/colors'
import { NavigationProps } from '~/@types/navigation'

import {
  useIsHover,
  useIsomorphicLayoutEffect,
  useMeasureObserver,
} from '@unlikelystudio/react-hooks'

import { UIThemeProps } from '~/components/Navigation/Navigation'
import AccountButton from '~/components/Navigation/Navigation/Buttons/AccountButton'
import CartButton from '~/components/Navigation/Navigation/Buttons/CartButton'
import SearchButton from '~/components/Navigation/Navigation/Buttons/SearchButton'
import NavigationPanel from '~/components/Navigation/Navigation/NavigationDesktop/Panel'
import BrandLogo from '~/components/UI/BrandLogo'
import NavItemCta from '~/components/UI/NavItemCta'

import { useNavPanelLinks } from '~/providers/NavPanelLinksProvider'

import getIsAccountHidden from '~/utils/get-is-account-hidden'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface NavigationDesktopProps
  extends Omit<NavigationProps, 'banner'> {
  className?: string
  hasLongNavItems?: boolean
}

function NavigationDesktop({
  className,
  hasScrolled,
  links,
  theme,
  logoClassName,
  scrolledTheme,
  hasLongNavItems,
  searchLink,
  accountLink,
  ...rest
}: NavigationDesktopProps) {
  // States
  const [panelIndex, setPanelIndex] = useState(null)
  const [navItemHovered, setNavItemHovered] = useState(true)
  const [panelHovered, setPanelHovered] = useState(true)
  const linksContainerRef = useRef()
  const linksContainerBounds = useMeasureObserver(
    linksContainerRef,
    'getBoundingClientRect',
  )

  // Events
  const [navigationHovered, mouseEvents] = useIsHover()
  const [isHover, callbacks] = useIsHover({
    onMouseLeave: () => setPanelIndex(null),
  })

  // Links container bounds used to align panel
  const { setTopLinksBounds } = useNavPanelLinks()

  useEffect(() => {
    setTopLinksBounds(linksContainerBounds)
  }, [linksContainerBounds])

  const onScroll = useDebouncedCallback(() => {
    if (panelIndex) {
      setPanelIndex(null)
      setNavItemHovered(false)
      window.removeEventListener('scroll', onScroll)
    }
  }, 10)

  useIsomorphicLayoutEffect(() => {
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  // Routing
  const router = useRouter()

  const asPanel = (index) =>
    index !== null && links?.[index]?.secondLevelItems?.length > 0

  const isOpen =
    // Content of the panel
    asPanel(panelIndex) &&
    // Panel hovered or nav item hovered
    (navItemHovered || panelHovered || navigationHovered)

  // Listen router to handle route change hide panel
  // useEffect(() => {
  //   setPanelIndex(null)
  //   setNavItemHovered(false)
  //   setPanelHovered(false)
  // }, [router?.asPath])

  const UIThemes: UIThemeProps = useMemo(
    () => ({
      ctaTheme:
        theme === GlobalThemeColors.White && !isHover && !hasScrolled
          ? GlobalThemeColors.WhiteOnShade1
          : GlobalThemeColors.Shade1,
      iconTheme: isHover || hasScrolled ? GlobalThemeColors.Black : theme,
    }),
    [theme, isHover, hasScrolled],
  )

  return (
    <nav
      className={cx(css.NavigationDesktop, className, theme, {
        [`${scrolledTheme}Scroll`]: scrolledTheme,
        hasScrolled,
        isHover,
        hasLongNavItems,
      })}
      {...callbacks}>
      <div className={css.whiteBackground} />
      <div className={cx(css.topContainer)}>
        <div className={cx(css.navigationTop)}>
          <div className={cx(css.leftContent)}>
            <BrandLogo
              theme={UIThemes.iconTheme}
              className={cx(css.logo, logoClassName)}
            />
          </div>
          <div className={cx(css.centerContent)} ref={linksContainerRef}>
            {links?.length > 0 && (
              <div className={cx(css.linksContainer)} {...mouseEvents}>
                {links?.map((item, index) => {
                  return (
                    <NavItemCta
                      className={cx(css.link, {
                        withoutLink: !item?.link?.href,
                      })}
                      key={`first_level_${index}`}
                      theme={UIThemes.ctaTheme}
                      isActive={isOpen && index === panelIndex}
                      triggersPanel
                      onMouseEnter={(e) => {
                        window.addEventListener('scroll', onScroll)
                        setNavItemHovered(true)
                        setPanelIndex(index)
                      }}
                      onMouseLeave={() => {
                        setNavItemHovered(false)
                      }}
                      {...item?.link}>
                      {item?.label}
                    </NavItemCta>
                  )
                })}
              </div>
            )}
          </div>
          <div className={cx(css.rightContent)}>
            {!getIsAccountHidden && (
              <AccountButton
                className={cx(css.iconLink, css.search)}
                theme={UIThemes.iconTheme}
                href={null}
              />
            )}
            {searchLink && (
              <SearchButton
                className={cx(css.iconLink, css.search)}
                theme={UIThemes.iconTheme}
                {...searchLink}
              />
            )}
            <CartButton
              className={cx(css.iconLink, css.cart)}
              href={null}
              theme={UIThemes.iconTheme}
            />
          </div>
        </div>
      </div>
      {/* Subpanel */}

      {isOpen && (
        <div className={cx(css.panelContainer, { isOpen })}>
          <NavigationPanel
            {...links[panelIndex]}
            key={`panel_${panelIndex}`}
            onMouseEnter={() => setPanelHovered(true)}
            onMouseLeave={() => setPanelHovered(false)}
          />
        </div>
      )}
    </nav>
  )
}

NavigationDesktop.defaultProps = {}

export default NavigationDesktop
