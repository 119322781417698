import classnames from 'classnames/bind'
import { forwardRef, useRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { ctaNegativeTheme } from '~/lib/negative-theme-colors'

import { useMeasureObserver } from '@unlikelystudio/react-hooks'

import Cta, { BaseProps } from '~/components/Abstracts/Cta'

import { useNavPanelLinks } from '~/providers/NavPanelLinksProvider'
import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type NavItemCtaTextPreset =
  | GlobalTextPreset.Cta12Primary
  | GlobalTextPreset.Cta12PrimaryReg
  | GlobalTextPreset.Cta14PrimaryReg
  | GlobalTextPreset.Cta18Primary

export type NavItemCtaColors =
  | GlobalThemeColors.Shade1
  | GlobalThemeColors.Shade1Darker
  | GlobalThemeColors.WhiteOnShade1

export interface NavItemCtaProps extends BaseProps {
  className?: string
  isActive?: boolean
  isCentered?: boolean
  theme?: NavItemCtaColors
  uppercase?: boolean
  triggersPanel?: boolean
  innerRef?: any
  textPreset?: NavItemCtaTextPreset
}

function NavItemCta({
  className,
  textPreset,
  uppercase = true,
  isActive,
  innerRef,
  isCentered,
  triggersPanel,
  ...rest
}: NavItemCtaProps) {
  const props = {
    css,
    cx,
    negativeColor: ctaNegativeTheme(rest.theme),
  }

  const panelTriggerRef = useRef()
  const boundRef = innerRef ?? panelTriggerRef
  const itemBounds = useMeasureObserver(boundRef, 'getBoundingClientRect')
  const { setHoverLink } = useNavPanelLinks()

  const textStyle = useStyle({
    textPreset,
    textStyling: uppercase ? GlobalTextStyling.UpperCase : null,
  })

  return (
    <Cta
      ref={boundRef}
      className={cx(className, textStyle, css.NavItemCta, {
        isActive,
        isCentered,
      })}
      {...props}
      {...rest}
      onMouseEnter={(e) => {
        rest?.onMouseEnter?.(e)
        if (triggersPanel) {
          setHoverLink?.(itemBounds)
        }
      }}
    />
  )
}

NavItemCta.defaultProps = {
  theme: GlobalThemeColors.Shade1,
  textPreset: GlobalTextPreset.Cta12Primary,
  withBackground: true,
  withBorder: false,
  isCentered: true,
}

// export default NavItemCta

function NavItemCtaForwarded(props, ref) {
  return <NavItemCta innerRef={ref} {...props} />
}

export default forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  NavItemCtaProps
>(NavItemCtaForwarded)
