import multistore from '~/../config/multistore.json'
import {
  DEFAULT_CURRENCY,
  DEFAULT_INTL,
  DEFAULT_LOCALE,
  LOCALES,
} from '~/lib/constants'
import getCurrency from '~/lib/get-currency'

export function getLang(locale: string) {
  return multistore?.[locale]?.lang ?? locale
}

export function getStoryblokCardPriceKey(locale: string = DEFAULT_LOCALE) {
  const currency = getCurrency(locale) ?? DEFAULT_CURRENCY
  return `card_price_${currency ?? DEFAULT_CURRENCY}`
}

export function getAllStoryblokCardPriceKeys() {
  return LOCALES.map((locale) => getStoryblokCardPriceKey(locale)) ?? []
}

export function getIntlLocale(locale: string) {
  return multistore?.[locale]?.intl ?? DEFAULT_INTL
}

export function getCountryName(locale: string) {
  return multistore?.[locale ?? DEFAULT_LOCALE]?.name
}
