import classnames from 'classnames/bind'
import { ReactNode, useRef, useState } from 'react'

import Input, { InputProps } from '~/components/Abstracts/Form/Input'

import uniqueId from '~/utils/unique-id'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface CheckboxProps extends InputProps {
  className?: string
  inputClassName?: string
  contentClassName?: string
  labelClassName?: string
  checkmarkClassName?: string
  checkmarkActiveClassName?: string
  checkmarkActive?: ReactNode | ReactNode[] | string | null
  label?: ReactNode | ReactNode[] | string
  children?: ReactNode | ReactNode[]
  id?: string
}

function Checkbox({
  className,
  inputClassName,
  contentClassName,
  labelClassName,
  checkmarkClassName,
  checkmarkActiveClassName,
  checkmarkActive,
  errorClassname,
  label,
  disabled,
  id,
  children,
  ...inputProps
}: CheckboxProps) {
  const [error, setError] = useState(null)
  const idRef = useRef(uniqueId())

  const cssError =
    (inputProps.withError || error?.type || error?.types?.length > 0) &&
    inputProps.withError
      ? errorClassname
      : null

  return (
    <label
      className={cx(css.Checkbox, className, cssError, { disabled })}
      htmlFor={id}>
      <Input
        type="checkbox"
        {...inputProps}
        id={id}
        className={cx(inputClassName, css.input)}
        onError={(err) => setError(err)}
      />
      <div className={cx(contentClassName, css.contentClassName)}>
        <span className={cx(css.checkmark, checkmarkClassName, cssError)}>
          <span
            className={cx(
              css.checkmarkActive,
              checkmarkActiveClassName,
              cssError,
            )}>
            {checkmarkActive ?? null}
          </span>
        </span>
        <span className={cx(css.label, cssError, labelClassName)}>{label}</span>
      </div>
      {children}
    </label>
  )
}

Checkbox.defaultProps = {}

export default Checkbox
