import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import Link, { LinkProps } from '~/components/Abstracts/Link'
// import { Link, LinkProps } from '@unlikelystudio/react-abstract-components'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import BlurredCta from '~/components/UI/BlurredCta'
import Image, { ImageProps } from '~/components/UI/Image'
import TagCustomizable from '~/components/UI/TagCustomizable'

import { useStyle } from '~/providers/StyleProvider'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_RATIO = 1 / 1

export interface PushNotificationProps {
  className?: string
  isClosable?: boolean
  hasCustomizableTag?: boolean
  isMenuDesktop?: boolean
  image?: ImageProps
  title?: RichTextBlock
  link?: LinkProps
  onClose?: () => void
}

function PushNotification({
  className,
  hasCustomizableTag,
  isClosable,
  isMenuDesktop,
  image,
  title,
  link,
  onClose,
}: PushNotificationProps) {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Label10Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const hasTextualContent = hasCustomizableTag || isRTFilled(title) || link

  const PushNotificationChildren = (
    <>
      {hasTextualContent && (
        <div className={css.left}>
          <div className={css.leftContent}>
            {hasCustomizableTag && (
              <div className={css.tags}>
                <TagCustomizable />
              </div>
            )}
            {title && (
              <RichText render={title} className={cx(css.title, titleStyle)} />
            )}
            {link && (
              <BlurredCta
                {...link}
                isDiv
                href={null}
                theme={GlobalThemeColors.Black}
                className={css.cta}
              />
            )}
          </div>
        </div>
      )}
      <div className={css.right}>
        {image && (
          <Image
            className={cx(css.image)}
            draggable="false"
            layout="fill"
            objectFit="cover"
            asPlaceholder
            sizes="165px"
            {...image}
          />
        )}
      </div>
    </>
  )

  return (
    <div className={cx(css.PushNotification, className, { isMenuDesktop })}>
      {link ? (
        <Link className={cx(css.link)} {...link}>
          {PushNotificationChildren}
        </Link>
      ) : (
        <>{PushNotificationChildren}</>
      )}
    </div>
  )
}

PushNotification.defaultProps = {
  isMenuDesktop: false,
}

export default PushNotification
