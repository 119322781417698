import classnames from 'classnames/bind'
import { GlobalTextPreset } from '~/@types/text-preset'

import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import InlineCta from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'

import { isRTFilled } from '~/utils/check-empty-string'

import css from './styles.module.scss'

const cx = classnames.bind(css)
export type FooterColumnProps = {
  className?: string
  titleStyle?: string
  title: RichTextBlock
  items: {
    title: string
    href: string
  }[]
  index?: number
}
export function FooterColumn({
  title,
  titleStyle,
  items,
  className,
  index,
}: FooterColumnProps) {
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Cta10Primary,
  })

  return (
    <div className={cx(css.FooterColumn, className)}>
      {isRTFilled(title) && (
        <RichText className={cx(css.title, titleStyle)} render={title} />
      )}
      <ul>
        {items.map((link) => (
          <li>
            <InlineCta
              className={css.link}
              lineProps={{
                initialLineState: 'none',
              }}
              textPreset={GlobalTextPreset.Cta10Primary}
              {...link}
            />
          </li>
        ))}
        {/* {index === 2 && (
          <li>
            <a
              className={cx(css.link, textStyle)}
              href="https://unlikely.technology/"
              target="blank">
              tech by Unlikely Technology
            </a>
          </li>
        )} */}
      </ul>
    </div>
  )
}
