import { CheckoutProps } from '~/@types/cart'
import { Nullish } from '~/@types/generic'

import { UnlikelyCart } from '@unlikelystudio/commerce-connector'

import { customAttributesAsObject } from '~/utils/custom-attributes'
import { getLang } from '~/utils/locales'

import serializePrice from '~/data/serialize-price'

function applyLocaleToCheckout(url: Nullish<string>, locale: Nullish<string>) {
  if (!url) return
  const lang = getLang(locale)
  const processedUrl = new URL(url)
  processedUrl.searchParams.append('locale', lang)
  return processedUrl?.href
}

export default function serializeCart(
  cart: UnlikelyCart,
  locale: string,
): Omit<CheckoutProps, 'products'> {
  const customAttributes = customAttributesAsObject(cart?.attributes ?? [])

  return {
    customAttributes,
    total: cart
      ? serializePrice(
          locale,
          cart?.cost?.totalAmount?.currencyCode,
          cart?.cost?.totalAmount?.amount,
        )
      : null,
    subtotal: cart
      ? serializePrice(
          locale,
          cart?.cost?.subtotalAmount?.currencyCode,
          cart?.cost?.subtotalAmount?.amount,
        )
      : null,
    totalTax: cart
      ? serializePrice(
          locale,
          cart?.cost?.totalTaxAmount?.currencyCode,
          cart?.cost?.totalTaxAmount?.amount,
        )
      : null,
    checkoutUrl: applyLocaleToCheckout(cart?.checkoutUrl, locale) ?? null,
    productsQuantity: cart?.lines?.reduce?.((previousValue, line) => {
      return previousValue + (line?.quantity ?? 0)
    }, 0),
  }
}
