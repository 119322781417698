import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import { isIOS } from '~/utils/is-ios'

export default function useIsIOS() {
  useIsomorphicLayoutEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      body.classList.toggle('ios', isIOS)
    }
  }, [isIOS])
}
