import classnames from 'classnames'
import { useTranslate } from 'react-polyglot'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { Alerts } from '@unlikelystudio/react-abstract-components'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function AlertCenter() {
  const t = useTranslate()

  const ANIMATE = {
    opacity: [0, 1, 1, 0],
    y: ['100%', '0%', '0%', '0%'],
    transition: {
      duration: 4,
      times: [0, 0.25, 0.8, 1],
      ease: ['easeOut', 'linear', 'linear'],
    },
  }

  const titleTextStyle = useStyle({
    textPreset: GlobalTextPreset.Label10Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const messageTextStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Secondary,
  })

  return (
    <Alerts
      className={css.AlertCenter}
      alertClassName={css.Alert}
      closeButtonClassName={cx(css.CloseIcon)}
      titleClassName={cx(css.title, titleTextStyle)}
      textClassName={cx(css.paragraph, messageTextStyle)}
      closeLabel={t('aria_close')}
      animate={ANIMATE}
    />
  )
}

export default AlertCenter
