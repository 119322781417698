import { DEFAULT_LOCALE } from '~/lib/constants'

import { getIntlLocale } from '~/utils/locales'
import numberAsBoolean from '~/utils/number-as-boolean'

export default function serializePrice(
  locale: string = DEFAULT_LOCALE,
  currencyCode: string,
  amount: number,
): string {
  if (!locale || !currencyCode || !numberAsBoolean(amount)) return null

  const intl = getIntlLocale(locale)

  return (
    new Intl.NumberFormat(intl, {
      style: 'currency',
      currency: currencyCode,
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
      .format(amount)
      // Remove unbreakable space
      .replace(/\u00a0/g, '')
  )
}
