import classnames from 'classnames/bind'
import Head from 'next/head'
import React, { useMemo, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { GlobalThemeColors } from '~/@types/colors'
import { NavigationProps } from '~/@types/navigation'

import { useMeasureObserver } from '@unlikelystudio/react-hooks'

import NavigationDesktop from '~/components/Navigation/Navigation/NavigationDesktop'
import NavigationMobile from '~/components/Navigation/Navigation/NavigationMobile'
import { NavItemCtaColors } from '~/components/UI/NavItemCta'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import NavPanelLinks from '~/providers/NavPanelLinksProvider'

import useTextColorFromTheme from '~/hooks/useTextColorFromTheme'

import NavBanner, { useNavBannerContext } from './NavBanner'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface UIThemeProps {
  ctaTheme?: NavItemCtaColors
  iconTheme?: GlobalThemeColors.White | GlobalThemeColors.Black
}

function Navigation({ className, banner, ...rest }: NavigationProps) {
  const [inViewRef, inView] = useInView({ initialInView: true })
  const { theme } = useGlobalData()
  const navTheme = theme?.nav
  const { textColor: scrolledTheme } = useTextColorFromTheme()
  const { links } = rest
  const [isVisible] = useNavBannerContext()
  const bannerRef = useRef(null)
  const { height: bannerHeight } = useMeasureObserver(
    bannerRef,
    'getBoundingClientRect',
  )

  const cssVariables = useMemo(() => {
    return `body {
        --banner-height: ${bannerHeight}px;
        --banner-displayed: ${Number(isVisible ?? 0)};
      }`
  }, [bannerHeight, isVisible, banner])

  return (
    <>
      <Head>{cssVariables && <style>{cssVariables}</style>}</Head>
      <div ref={inViewRef} className={css.NavigationScrollRef} />
      <header
        className={cx(
          css.Navigation,
          className,
          navTheme,
          `${scrolledTheme}ThemeScroll`,
        )}>
        {isVisible && (
          <NavBanner ref={bannerRef} {...banner} className={css.banner} />
        )}
        <div className={cx(css.container)}>
          <NavPanelLinks>
            <NavigationDesktop
              className={cx(css.desktopNavigation, css.hideOnSmallScreen)}
              hasLongNavItems={links?.length >= 5}
              logoClassName={cx(css.logo)}
              hasScrolled={!inView}
              theme={navTheme}
              scrolledTheme={scrolledTheme}
              {...rest}
            />
          </NavPanelLinks>

          <NavigationMobile
            className={cx(css.mobileNavigation, css.hideOnLargeScreen)}
            hasScrolled={!inView}
            logoClassName={cx(css.logo)}
            theme={navTheme}
            scrolledTheme={scrolledTheme}
            {...rest}
          />
        </div>
      </header>
    </>
  )
}

Navigation.defaultProps = {}

export default Navigation
