import classnames from 'classnames/bind'
import React from 'react'

import Spinner from '~/components/Abstracts/Spinner'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LoadingComponentProps {
  className?: string
  color?: string
}

function LoadingComponent({ className, color }: LoadingComponentProps) {
  return (
    <div className={cx(css.LoadingComponent, className)}>
      <div className={css.spinner}>
        <Spinner color={color} />
      </div>
    </div>
  )
}

LoadingComponent.defaultProps = {}

export default LoadingComponent
