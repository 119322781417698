import classnames from 'classnames/bind'
import { GlobalTextPreset } from '~/@types/text-preset'

import { LinkProps } from '@unlikelystudio/react-abstract-components'

import Link from '~/components/Abstracts/Link'
import Image, { ImageProps } from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_RATIO = 448 / 596
const IMAGE_SIZES = [
  { breakpoint: 'md', ratio: 448 / 1440 },
  { ratio: 311 / 375 },
]

export interface ImageWithCaptionProps {
  className?: string
  image?: ImageProps
  link?: LinkProps
  layout?: ImageProps['layout']
  caption?: string
}

function ImageWithCaption({
  className,
  link,
  image,
  caption,
  layout,
}: ImageWithCaptionProps) {
  const captionStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Secondary,
  })

  return (
    <Link {...link} className={cx(css.ImageWithCaption, className)}>
      {image && (
        <Image
          className={cx(css.image)}
          draggable={false}
          layout={layout ?? 'fill'}
          ratio={layout && layout === 'responsive' ? null : IMAGE_RATIO}
          sizesFromBreakpoints={IMAGE_SIZES}
          objectFit="cover"
          asPlaceholder
          {...image}
        />
      )}
      {caption && (
        <span className={cx(css.caption, captionStyle)}>{caption}</span>
      )}
    </Link>
  )
}

ImageWithCaption.defaultProps = {}

export default ImageWithCaption
