import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { NavigationProps } from '~/@types/navigation'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import LangSwitcher from '~/components/LangSwitcher'
import { UIThemeProps } from '~/components/Navigation/Navigation'
import AccountButton from '~/components/Navigation/Navigation/Buttons/AccountButton'
import BurgerButton from '~/components/Navigation/Navigation/Buttons/BurgerButton'
import CartButton from '~/components/Navigation/Navigation/Buttons/CartButton'
import CloseButton from '~/components/Navigation/Navigation/Buttons/CloseButton'
import SearchButton from '~/components/Navigation/Navigation/Buttons/SearchButton'
import { useNavBannerContext } from '~/components/Navigation/Navigation/NavBanner'
import NavigationSubPanel from '~/components/Navigation/Navigation/NavigationMobile/SubPanel'
import BrandLogo from '~/components/UI/BrandLogo'
import { ArrowIcon } from '~/components/UI/Icons'
import NavItemCta from '~/components/UI/NavItemCta'

import { useStyle } from '~/providers/StyleProvider'

import useBodyScrollLock from '~/hooks/useBodyScrollLock'

import getIsAccountHidden from '~/utils/get-is-account-hidden'

import { BUTTON, CTA } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function NavigationMobile({
  className,
  hasScrolled,
  links,
  theme,
  logoClassName,
  searchLink,
}: Omit<NavigationProps, 'banner'>) {
  const t = useTranslate()
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [panelIndex, setPanelIndex] = useState(null)

  const backButtonStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const gridStyle = useStyle({ grid: GlobalGridPreset.NAV_GRID })
  const [withBanner] = useNavBannerContext()
  useBodyScrollLock(isOpen)

  // Listen router to handle route change hide panel
  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false)
      setPanelIndex(null)
    }

    router.events.on('beforeHistoryChange', handleRouteChange)

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange)
    }
  }, [])

  const UIThemes: UIThemeProps = useMemo(
    () => ({
      ctaTheme:
        theme === GlobalThemeColors.White && !hasScrolled
          ? GlobalThemeColors.WhiteOnShade1
          : GlobalThemeColors.Shade1,
      iconTheme: hasScrolled || isOpen ? GlobalThemeColors.Black : theme,
    }),
    [theme, hasScrolled, isOpen],
  )

  return (
    <div
      className={cx(css.NavigationMobile, className, theme, {
        hasScrolled,
        isOpen,
      })}>
      <div className={css.whiteBackground} />
      <div className={gridStyle}>
        <div className={cx(css.top)}>
          <div className={css.topLeft}>
            {isOpen ? (
              <CloseButton
                className={cx(css.link, css.burgerButton, css.closeButton)}
                theme={UIThemes.iconTheme}
                aria-label={t(BUTTON.CLOSE_MENU)}
                onClick={() => {
                  setIsOpen(false)
                  setPanelIndex(null)
                }}
                href={null}
              />
            ) : (
              <BurgerButton
                className={cx(css.link, css.burgerButton)}
                theme={UIThemes.iconTheme}
                aria-label={t(BUTTON.OPEN_MENU)}
                onClick={() => setIsOpen(true)}
                href={null}
              />
            )}
          </div>
          <div className={css.topCenter}>
            <BrandLogo
              theme={UIThemes.iconTheme}
              className={cx(css.logo, logoClassName)}
            />
          </div>
          <div className={css.topRight}>
            {!getIsAccountHidden && (
              <AccountButton
                className={cx(css.iconLink, css.accountButton)}
                theme={UIThemes.iconTheme}
                href={null}
              />
            )}
            {searchLink && (
              <SearchButton
                className={cx(css.link, css.searchButton)}
                theme={UIThemes.iconTheme}
                {...searchLink}
              />
            )}
            <CartButton
              className={cx(css.link, css.cartButton)}
              aria-label={t(BUTTON.OPEN_CART)}
              theme={UIThemes.iconTheme}
              href={null}
            />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.15 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.15 },
            }}
            className={cx(css.panel, {
              isOpen: panelIndex !== null,
              withBanner,
            })}>
            <div className={cx(css.panelContent)}>
              {panelIndex === null ? (
                <>
                  <div className={css.firstLevelLinks}>
                    <div className={gridStyle}>
                      <div className={css.gridRow}>
                        {links?.map((item, index) => {
                          return !item?.secondLevelItems ? (
                            <NavItemCta
                              isCentered={false}
                              className={cx(css.columnLink)}
                              textPreset={GlobalTextPreset.Cta18Primary}
                              key={`main_link_${index}`}
                              {...item?.link}>
                              {item?.label}
                            </NavItemCta>
                          ) : (
                            <NavItemCta
                              isCentered={false}
                              className={cx(css.columnLink)}
                              key={`main_link_${index}`}
                              textPreset={GlobalTextPreset.Cta18Primary}
                              isActive
                              onClick={() => setPanelIndex(index)}>
                              {item?.label}
                            </NavItemCta>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div className={css.bottomFirstLevel}>
                    <div className={gridStyle}>
                      <div className={css.bottomFirstLevelLinks}>
                        {/* Disabled for V1 */}
                        {/* {accountLink && (
                          <AccountButton
                            className={cx(css.bottomItem)}
                            theme={theme}
                            {...accountLink}
                          />
                        )} */}

                        <LangSwitcher
                          className={cx(css.selectLanguage, css.bottomItem)}
                          selectClassName={css.selectItem}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={css.subPanel}>
                  <div className={gridStyle}>
                    <div className={cx(css.panelBack, css.gridRow)}>
                      <button
                        className={cx(css.buttonBack, backButtonStyle, {
                          back: true,
                        })}
                        aria-label={t(CTA.BACK)}
                        onClick={() => setPanelIndex(null)}>
                        <ArrowIcon className={css.icon} />
                        {t(CTA.BACK)}
                      </button>
                      <div className={cx(css.buttonBack, backButtonStyle)}>
                        {links?.[panelIndex]?.label}
                      </div>
                    </div>
                  </div>
                  {links?.[panelIndex] && (
                    <NavigationSubPanel {...links[panelIndex]} />
                  )}
                </div>
              )}
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </div>
  )
}

NavigationMobile.defaultProps = {}

export default NavigationMobile
