import classnames from 'classnames/bind'
import { ReactNode } from 'react'
import processEnvPublic from 'unlikely-env/public'

import ConditionalCrawlerDetector from '~/components/Abstracts/ConditionalCrawlerDetector'
import HackImageLcp from '~/components/Abstracts/HackImageLcp'
import PanelManager from '~/components/Abstracts/Panels/PanelManager'
import PopInManager from '~/components/Abstracts/PopInManager'
import { VercelSpeedInsights } from '~/components/Abstracts/VercelSpeedInsights'
import Navigation from '~/components/Navigation/Navigation'
import {
  SLICES_WITHOUT_MARGIN_BOTTOM,
  SLICES_WITHOUT_MARGIN_TOP,
  TSLICES_WITHOUT_MARGIN_BOTTOM,
  TSLICES_WITHOUT_MARGIN_TOP,
} from '~/components/Page/slices-without-margin'
import BackToTop from '~/components/UI/BackToTop'
import Footer from '~/components/UI/Footer'

import useStoryblok from '~/hooks/useStoryblok'

import { DefaultPageData } from '~/data/page-data/serializer'

import Meta from '../Abstracts/Meta'
import { useNavBannerContext } from '../Navigation/Navigation/NavBanner'
import { ToastCenter } from '../Toast'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PageProps extends DefaultPageData {
  children?: ReactNode | ReactNode[]
}
function Page({ children, metas, global, theme, slices, preview }: PageProps) {
  useStoryblok(preview)

  const withoutMarginTop = SLICES_WITHOUT_MARGIN_TOP.includes(
    slices?.[0]?.type as TSLICES_WITHOUT_MARGIN_TOP[number],
  )

  const withoutMarginBottom = SLICES_WITHOUT_MARGIN_BOTTOM.includes(
    slices?.[slices.length - 1]?.type as TSLICES_WITHOUT_MARGIN_BOTTOM[number],
  )

  const [isVisible] = useNavBannerContext()
  const isMaintenanceMode =
    processEnvPublic('NEXT_PUBLIC_MAINTENANCE_MODE') === 'true'

  return (
    <>
      <Meta {...metas} />
      {!isMaintenanceMode && <Navigation {...global?.navigation} />}
      <HackImageLcp />
      <main
        className={cx(css.Page, {
          forcedMarginFromBanner:
            isVisible && global?.navigation?.banner?.texts?.length > 0,
          withoutMarginTop,
          withoutMarginBottom,
        })}>
        {children}
      </main>
      <ConditionalCrawlerDetector>
        <>
          <ToastCenter />
        </>
      </ConditionalCrawlerDetector>
      <BackToTop />
      {!isMaintenanceMode && (
        <Footer {...global?.footer} newsletterData={global?.newsletter} />
      )}
      <ConditionalCrawlerDetector>
        <>
          <PopInManager />
          <PanelManager />
          <VercelSpeedInsights />
        </>
      </ConditionalCrawlerDetector>
    </>
  )
}

Page.defaultProps = {}

export default Page
