import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'

import NavigationButtonIcon from '~/components/Navigation/Navigation/NavigationButtonIcon'
import { NavigationButton } from '~/components/Navigation/Navigation/types'
import { SearchIcon } from '~/components/UI/Icons'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function SearchButton({ className, theme, ...rest }: NavigationButton) {
  const t = useTranslate()
  return (
    <NavigationButtonIcon
      className={className}
      aria-label={t(GENERAL.SEARCH)}
      theme={theme}
      {...rest}>
      <SearchIcon className={cx(css.icon)} />
    </NavigationButtonIcon>
  )
}

SearchButton.defaultProps = {}

export default SearchButton
