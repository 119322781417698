import classnames from 'classnames/bind'
import { ReactNode, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { TRACKING_EVENTS } from '~/lib/constants'

import { useInputPreset } from '~/components/Abstracts/Form/Input/hooks'
import RichText, { RichTextBlock } from '~/components/Abstracts/RichText'
import Spinner from '~/components/Abstracts/Spinner'
import Checkbox from '~/components/Form/Checkbox'
import Input, { InputProps } from '~/components/Form/Input'
import BlurredCta from '~/components/UI/BlurredCta'

import RecaptchaHandler from '~/providers/RecaptchaProvider/RecaptchaHandler'
import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useShopifyNewsletterMutation from '~/hooks/account/useShopifyNewsletterMutation'
import useCurrency from '~/hooks/useCurrency'
import useFormWithMutation from '~/hooks/useFormWithMutation'
import useLang from '~/hooks/useLang'

import { FORM } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface NewsletterProps extends InputProps {
  className?: string
  formClassName?: string
  inputClassName?: string
  resultClassName?: string
  gdprClassName?: string
  textSubmit?: boolean
  children?: ReactNode
  footer?: ReactNode | ReactNode[]
  gdprLabel?: RichTextBlock
  resultText?: RichTextBlock
  layout?: 'small' | 'default'
  onSuccess?(): void
}

function Newsletter({
  className,
  formClassName,
  inputClassName,
  textSubmit,
  resultClassName,
  children,
  placeholder,
  gdprLabel,
  resultText,
  gdprClassName,
  layout = 'default',
  onSuccess,
  ...rest
}: NewsletterProps) {
  const t = useTranslate()
  const tracker = useTracker()

  const lang = useLang()
  const currency = useCurrency()
  const props = useInputPreset('email', {
    placeholder: placeholder || t(FORM.NEWSLETTER_EMAIL),
    ...rest,
  })

  const successText = useStyle({
    textPreset: GlobalTextPreset.Label12Secondary,
  })

  const successTitleText = useStyle({
    textPreset: GlobalTextPreset.Label10Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const checboxStyle = useStyle({
    textStyling: GlobalTextStyling.UpperCase,
  })

  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Input16Primary,
  })

  const [result, setResult] = useState(false)

  const { mutate: newsletterMutation, isLoading } =
    useShopifyNewsletterMutation(
      {
        onSuccess: () => {
          setResult(t(FORM.NEWSLETTER_SUCCESS))
          tracker.emit<typeof TRACKING_EVENTS.NEWSLETTER_SUBSCRIPTION>(
            TRACKING_EVENTS.NEWSLETTER_SUBSCRIPTION,
          )
          onSuccess?.()
        },
      },
      (...params) => form.setError(...params),
    )

  const { form, onSubmit } = useFormWithMutation(
    newsletterMutation,
    null,
    'newsletter',
  )

  const { email: mailField, gdpr: gdprField } = form?.watch()

  useEffect(() => {
    if (
      form?.formState?.errors &&
      Object.keys(form.formState.errors).length > 0
    )
      setResult(false)
  }, [form.formState])

  const submitActive = mailField?.length > 0 && gdprField

  const SubmitButton = ({
    children,
  }: {
    children?: ReactNode | ReactNode[]
  }) => (
    <div className={css.submitButton}>
      {isLoading ? (
        <Spinner className={cx(css.spinner)} />
      ) : (
        <BlurredCta
          className={cx(css.submit, { textSubmit })}
          isBig={layout === 'default'}
          theme={
            layout === 'small'
              ? GlobalThemeColors.Transparent
              : submitActive
              ? GlobalThemeColors.Black
              : GlobalThemeColors.Shade1
          }
          type="submit">
          {children ?? (textSubmit && t(FORM.SUBMIT))}
        </BlurredCta>
      )}
    </div>
  )

  return (
    <div className={cx(className, css?.[`layout-${layout}`])}>
      {!result && children}
      <RecaptchaHandler>
        <FormProvider {...form}>
          <form className={cx(formClassName)} onSubmit={onSubmit}>
            {!result && (
              <>
                {layout === 'small' ? (
                  <div className={css.inputContainer}>
                    <Input
                      inputClassName={css.input}
                      className={cx(textStyle)}
                      onChange={() => setResult(false)}
                      {...props}
                    />
                    <SubmitButton>{t(FORM.OK)}</SubmitButton>
                  </div>
                ) : (
                  <Input
                    inputClassName={css.input}
                    className={cx(textStyle)}
                    onChange={() => setResult(false)}
                    {...props}
                  />
                )}
                <Input
                  className={cx({ isHidden: true })}
                  type="hidden"
                  name="tags"
                  value={`lang:${lang},currency:${currency}`}
                />

                {gdprLabel && (
                  <Checkbox
                    id="gdpr"
                    name="gdpr"
                    required={true}
                    className={cx(css.gdpr, gdprClassName, checboxStyle)}
                    textPreset={GlobalTextPreset.Label10Primary}
                    theme={GlobalThemeColors.Black}
                    label={gdprLabel}
                  />
                )}

                {layout === 'default' && <SubmitButton />}
              </>
            )}

            {result && (
              <div className={cx(resultClassName, css.result)}>
                <span className={cx(css.resultTitle, successTitleText)}>
                  {result}
                </span>
                {resultText && (
                  <RichText
                    render={resultText}
                    className={cx(css.resultText, successText)}
                  />
                )}
              </div>
            )}
          </form>
        </FormProvider>
      </RecaptchaHandler>
    </div>
  )
}

Newsletter.defaultProps = {
  textSubmit: false,
}

export default Newsletter
