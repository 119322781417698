import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'

import Link from '~/components/Abstracts/Link'

import useLocale from '~/hooks/useLocale'

import { GENERAL } from '~/data/dictionary'

import { ReactComponent as LogoByCharlot } from './logo_bycharlot.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BrandLogoProps {
  className?: string
  theme: GlobalThemeColors.White | GlobalThemeColors.Black
}

function BrandLogo({ className, theme }: BrandLogoProps) {
  const { pathname } = useRouter()
  const isHomepage = pathname === '/'
  const t = useTranslate()
  const locale = useLocale()
  const linkProps = {
    title: t(GENERAL.LOGO_TITLE),
    href: `/${locale}`,
  }

  const Logo = <LogoByCharlot className={cx(css.logo)} />

  return isHomepage ? (
    <h1 className={cx(css.BrandLogo, className, css?.[`${theme}Theme`])}>
      <span className={cx(css.hiddenTitle)}>{t(GENERAL.LOGO_TITLE)}</span>
      <Link className={cx(css.link)} {...linkProps}>
        {Logo}
      </Link>
    </h1>
  ) : (
    <Link
      className={cx(css.BrandLogo, className, css.link, css?.[`${theme}Theme`])}
      {...linkProps}>
      {Logo}
    </Link>
  )
}

BrandLogo.defaultProps = {}

export default BrandLogo
