import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { FirstLevelNavItem } from '~/@types/navigation'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import PushNotification from '~/components/Abstracts/PushNotification'
import NavItemCta from '~/components/UI/NavItemCta'

import { useStyle } from '~/providers/StyleProvider'

import { isRTFilled } from '~/utils/check-empty-string'

import { PANEL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export default function NavigationSubPanel({
  link,
  label,
  enableSeeAll,
  secondLevelItems,
  push,
}: FirstLevelNavItem) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.NAV_GRID })
  const t = useTranslate()
  const showNotification = push?.link || isRTFilled(push?.title)

  const columnTitleStyle = useStyle({
    color: GlobalThemeColors.Black,
    textPreset: GlobalTextPreset.Cta12Primary,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={css.NavigationSubPanel}>
      <div className={gridStyle}>
        <div className={css.gridRow}>
          {link && (
            <NavItemCta
              key={`second_level_link_see_all`}
              className={cx(css.columnLink, css.seeAll)}
              theme={GlobalThemeColors.Shade1}
              uppercase={false}
              textPreset={GlobalTextPreset.Cta14PrimaryReg}
              isCentered={false}
              {...link}>
              {enableSeeAll ? t(PANEL.SEE_ALL) : label}
            </NavItemCta>
          )}
          <div className={css.secondLevelLinks}>
            {secondLevelItems?.map((column, columnIndex) => {
              return (
                <div className={css.column} key={`panel_column_${columnIndex}`}>
                  <h5 className={cx(css.columnTitle, columnTitleStyle)}>
                    {column?.title}
                  </h5>
                  {column?.links?.[0]?.map((link, index) => {
                    return (
                      <NavItemCta
                        key={`second_level_link_${columnIndex}_${index}`}
                        className={css.columnLink}
                        uppercase={false}
                        textPreset={GlobalTextPreset.Cta14PrimaryReg}
                        theme={GlobalThemeColors.Shade1}
                        isCentered={false}
                        isActive
                        {...link}
                      />
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {showNotification && (
        <div className={css.bottomPush}>
          <PushNotification className={css.push} isMenuDesktop {...push} />
        </div>
      )}
    </div>
  )
}
