import { useState } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'

import { useTheme } from '~/providers/ThemeProvider'

export default function useBreakpoint(breakpoint: string): boolean {
  const { direction = 'max', ssr = true, fallback = true } = {}

  const { screens } = useTheme()

  const query = `(${direction}-width: ${screens?.[breakpoint]})`

  const getMatches = () => {
    return window.matchMedia(query).matches
  }

  const [matches, setMatches] = useState<boolean>(ssr ? fallback : getMatches())

  function handleChange() {
    setMatches(getMatches())
  }

  useIsomorphicLayoutEffect(() => {
    const matchMedia = window.matchMedia(query)

    handleChange()

    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange)
    } else {
      matchMedia.addEventListener('change', handleChange)
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange)
      } else {
        matchMedia.removeEventListener('change', handleChange)
      }
    }
  }, [query])

  return matches
}
