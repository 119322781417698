import jwtDecode from 'jwt-decode'
import processEnvPublic from 'unlikely-env/public'

import type { GetShopifyStorefrontClientOptions } from '@unlikelystudio/commerce-connector'

export type JwtPublicShopifyToken = {
  devise: string
  name: string
} & GetShopifyStorefrontClientOptions

/**
 * Given a url, replace the domain name of the url with the new domain name
 * @param url - The URL of the page to be loaded.
 * @returns the url with the domain name changed.
 */
export function changeDomainUrlCheckout(url: string): string {
  if (url?.indexOf('wethenew.myshopify.com') > -1)
    return url.replace('wethenew.myshopify.com', 'checkout.wethenew.com')
  else return url
}

/**
 * It takes a locale and returns the credentials for the store associated with that locale
 * @param {string} locale - The locale of the store.
 * @returns A JSON object with the following keys:
 */
export function getStorePublicCredentials(
  withCheckoutReplaceUrl = true,
): JwtPublicShopifyToken | null {
  const token = processEnvPublic('NEXT_PUBLIC_SHOPIFY_TOKEN')

  if (!token) return null

  const decodedToken = jwtDecode<JwtPublicShopifyToken>(token)

  const storeUrl = withCheckoutReplaceUrl
    ? (changeDomainUrlCheckout(
        decodedToken?.storeUrl,
      ) as GetShopifyStorefrontClientOptions['storeUrl'])
    : decodedToken?.storeUrl

  return {
    ...decodedToken,
    storeUrl,
  }
}
